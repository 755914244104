import moment from 'moment/moment'
import React from 'react'
import { Tooltip } from 'react-tooltip'
import { currencyText } from '../../data/constants/constants'

export const Accounts = ({ user }) => {
  console.log(user)
  return (
    <div className="saving-account">
      {user.numeroCompte ? (
        <div className="saving-money">
          <div className="saving-flex">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_soleil.png'}
              className="saving-sun"
              height="30px"
              alt="soleil, montant, nom utilisateur"
            />
            <div className="started-mobile">
              <p className="saving-bolded">Compte de dépôt</p>
              <span> N°{user.numeroCompte}</span>
            </div>
          </div>
          <p className="saving-bolded saving-position">
            {user.balance} {currencyText}.
          </p>
        </div>
      ) : (
        <div style={{ padding: '0 15%', textAlign: 'center' }}>
          La création de votre compte est réussie, vous êtes bien connecté. Votre compte est en cours d'activation, veuillez patienter le temps qu'un opérateur finalise les opérations de contrôle. Nos équipes étant principalement composées de bénévoles, le délai d'activation est variable.
        </div>
      )}

      {user.accounts.map((account) => (
        <div className="saving-money">
          <div className="saving-flex">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_soleil.png'}
              className="saving-sun"
              height="30px"
              alt="soleil, montant, nom utilisateur"
            />
            <div className="started-mobile">
              <p className="saving-bolded">Compte de dépôt</p>
              <span> N°{account.id_compte}</span>
            </div>
          </div>
          <p className="saving-bolded saving-position">
            {account.solde} {currencyText}.
          </p>
        </div>
      ))}

      {user.prets?.map((pret) => (
        <>
          <Tooltip id={'pret-tooltip' + pret.numéro} className={'pret-tooltip'}>
            <span className={'pret-tooltip-property'}>
              Date d'anniversaire:{' '}
              <span>
                {moment(
                  'date_anniversaire' in pret ? pret.date_anniversaire : pret.date_fin
                ).format('DD/MM/YYYY')}
              </span>
            </span>
            <br />
            <br />
            <span className={'pret-tooltip-property'}>
              Moyen de mise à disposition du prêt: <span>{pret.m_versement}</span>
            </span>
          </Tooltip>
          <div className="saving-money" data-tooltip-id={'pret-tooltip' + pret.numéro}>
            <div className="saving-flex">
              <img
                src={process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_soleil.png'}
                className="saving-sun"
                height="30px"
                alt="soleil, montant, nom utilisateur"
              />
              <div className="started-mobile">
                <p className="saving-bolded">Contrat de prêt garanti </p>
                <span> N°{pret.numéro}</span>
              </div>
            </div>
            <p className="saving-bolded saving-position">
              {pret.montant} {currencyText}.
            </p>
          </div>
        </>
      ))}
    </div>
  )
}
