import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'

export const generateDownloadReactPDFDocument = async (document, filename) => {
  // Generate blob
  const blob = await pdf(document).toBlob()

  // Export as PDF
  saveAs(blob, filename)
}
