import { Image, StyleSheet, View } from '@react-pdf/renderer'
import { GenericTablePDF } from './GenericTablePDF'

export const StyledGenericTablePDF = ({ columns, data, ...rest }) => {
  return (
    <View style={styles.root}>
      <GenericTablePDF
        columns={columns}
        data={data}
        headerCellStyle={{
          color: 'black',
          backgroundColor: 'white',
          textTransform: 'uppercase',
          fontFamily: 'Helvetica',
          fontWeight: 600,
          fontSize: 8,
          padding: 5,
          borderColor: 'black',
        }}
        rowCellStyle={{
          color: 'black',
          fontFamily: 'Helvetica',
          borderColor: 'black',
          fontSize: 6,
        }}
        backgroundColorToAlternate={'rgba(0, 0, 0,0.05)'}
        rowBorderColor={'black'}
        headerBorderColor={'black'}
        {...rest}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  root: {
    marginTop: 10,
    paddingHorizontal: 30,
  },
})
