import { baseUrl } from '../constants/constants'
import axios from 'axios'
import Shop from '../models/Shop.ts'
import { adhHeaders } from './api'

export const getShopList = async () => {
  try {
    const response = await axios.get(`${baseUrl}/v_partenaires`, {
      headers: adhHeaders(),
    })
    return response.data.map((shopData) => new Shop(shopData))
  } catch (error) {
    throw error
  }
}
