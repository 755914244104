import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { createBeneficiary } from '../../data/services/apiBeneficiary'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { useSMSVerificationContext } from '../../utils/providers/SMSVerificationContext'
import RenderBeneficiaryState from '../../utils/renders/RenderBeneficiaryState'
import LoadingSpinner from '../UI/LoadingSpinner'
import { states } from './Beneficiaries'
import '../../style/screens/Beneficiaries.scss'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const AjouterBeneficiaire = () => {
  let query = useQuery()
  const name = query.get('name')
  const compte = query.get('compte')
  const { pageLoading, setPageLoading } = usePageAuth()
  let navigate = useNavigate()

  const [status, setStatus] = useState(null)

  const { RenderSMSVerification, inVerification, setInVerification } = useSMSVerificationContext()

  const onSuccess = () => {
    setStatus(states.ADD_SUCCESS)
  }

  useEffect(() => {
    setPageLoading(true)
    createBeneficiary({ numéro_compte_cible: compte, p_commentaire: name })
      .then((r) => {
        if (r === 'SMS') {
          setInVerification(true)
          setStatus(states.ADD_START)
          return
        }
        onSuccess()
      })
      .catch((r) => {
        setStatus(states.DELETE_FAIL)
      })
      .finally(() => setPageLoading(false))
  }, [])

  if (pageLoading || !status)
    return (
      <main className="page-msg">
        <LoadingSpinner />
      </main>
    )

  if (inVerification) {
    return (
      <main className="page-msg">
        <RenderSMSVerification onSuccess={onSuccess} />
      </main>
    )
  }

  return (
    <RenderBeneficiaryState
      states={states}
      currentState={status}
      handleReset={() => navigate('/compte')}
    />
  )
}

export default AjouterBeneficiaire
