import { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ranks } from '../../data/constants/constants'
import { getCaisse, getCaisseList } from '../../data/services/apiCaisse'
import { getUserDataSelf } from '../../data/services/apiUser'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { AuthContext } from '../../utils/providers/AuthContext'
import LoadingSpinner from '../UI/LoadingSpinner'

export const Delegation = () => {
  const { isConnected, logOutErrorHandler } = useContext(AuthContext)
  const { pageLoading, setPageLoading } = usePageAuth()
  const [errorMsg, setErrorMsg] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [successMsg, setSuccessMsg] = useState('')

  const [user, setUser] = useState()

  const [caisses, setCaisses] = useState([])

  useEffect(() => {
    setPageLoading(true)
    getUserDataSelf().then(async (user) => {
      if (user && user.level === ranks.ADMIN) {
        setUser(user)
        try {
          const caisses = await getCaisseList()
          setCaisses(caisses)
        } catch (e) {
          setErrorMsg('Une erreur est survenue pendant la récupération des caisses locales')
        }
        setPageLoading(false)
      } else {
        navigate('/compte')
        setPageLoading(false)
      }
    })
  }, [])

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button className="back-button-edit" onClick={() => setErrorMsg('')}>
        Retour
      </button>
    </main>
  ) : successMsg ? (
    <main className="page-msg">
      <p>{successMsg}</p>
    </main>
  ) : (
    <>
      <h2>Délégation</h2>
      <main className="caisse-locale">
        <div className="table-container" style={{ marginTop: '0' }}>
          {caisses.length > 0 ? (
            <table>
              <tbody>
                <tr>
                  <th>#</th>
                  <th>Code caisse</th>
                  <th>Nom caisse</th>
                </tr>

                {caisses.map((caisse, index) => {
                  return (
                    <Link
                      to={'/delegation/' + caisse.code_caisse}
                      className={'table-row'}
                      key={caisse.code_caisse}
                    >
                      <th>{index}</th>
                      <td>{caisse.code_caisse}</td>
                      <td>{caisse.nom_caisse}</td>
                    </Link>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <p className="msg-no-account">Aucun verrou ne correspond à la recherche</p>
          )}
        </div>
      </main>
    </>
  )
}
