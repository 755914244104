import { getTokenIsAdmin } from '../../utils/providers/AuthContext'

const headers = () => ({
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
})

export const adhHeaders = (post = false) => ({
  ...headers(),
  [post ? 'Content-Profile' : 'Accept-Profile']: 'adh_fl',
})

export const adminHeaders = (post = false) => ({
  ...headers(),
  [post ? 'Content-Profile' : 'Accept-Profile']: getTokenIsAdmin()
    ? 'administration_fl'
    : 'ctrl_fl',
})

export const controlleurHeaders = (post = false) => ({
  ...headers(),
  [post ? 'Content-Profile' : 'Accept-Profile']: 'ctrl_fl',
})

export const getFromCache = (cache, key) => {
  if (cache[key]) {
    return Promise.resolve(cache[key])
  }
  return null
}

export const cache = (cacheObj, key, func) => {
  if (cacheObj[key]) {
    return new Promise((resolve) => resolve(cacheObj[key]))
  }
  cacheObj[key] = new Promise((resolve, reject) => {
    func()
      .then((r) => {
        resolve(r)
        cacheObj[key] = r
      })
      .catch((e) => {
        reject(e)
        throw e
      })
  })
  return cacheObj[key]
}
