import '../../style/UI/LoadingSpinner.scss'

export default function LoadingSpinner({ margin = '40px auto', size = 100, fontSize = '9px' }) {
  const pxSize = size + 'px'
  return (
    <div className="loading-container" style={{ margin }}>
      <div className="loading" style={{ width: pxSize, height: pxSize }}></div>
      <div
        id="loading-text"
        style={{ width: pxSize, marginTop: size / 2 - size / 20 + 'px', fontSize }}
      >
        Chargement...
      </div>
    </div>
  )
}
