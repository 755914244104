export const ranks = {
  ADMIN: 'administrateur',
  LOCAL_ADMIN: 'LOCAL_ADMIN',
  USER: 'standard',
  CONTROLLEUR: 'controleur',
}

//Env: PUBLIC_URL à définir

export const monhost = process.env.REACT_APP_AHOST ?? 'https://jeanne.hopto.org'
//export const baseUrl = process.env.REACT_APP_BASE_URL ?? monhost + '/rest'
export const baseUrl = monhost + '/rest'

export const clientSecret =
  process.env.REACT_APP_CLIENT_SECRET ?? 'lakzJdg0g2DNPGqFIc7xFRA7Pv6Ycw2Q'

//export const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL ?? monhost + '/auth'
export const keycloakUrl = monhost + '/auth'
export const keycloakRealm = 'LFL'
export const keycloakClient = 'franclibre'

export const amountSuffix = process.env.REACT_APP_AMOUNT_SUFFIX ?? ''
export const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL ?? '₣'
export const currencyText = process.env.REACT_APP_CURRENCY_TEXT ?? 'UC'
export const currencyCode = 'FLF'

export const mdpoublieUrl =
  keycloakUrl +
  '/realms/' +
  keycloakRealm +
  '/login-actions/reset-credentials?client_id=' +
  keycloakClient

//export const registerUrl =  process.env.REACT_APP_REGISTER_URL ??  keycloakUrl +
//    '/realms/LFL/protocol/openid-connect/registrations?client_id=franclibre&response_type=code&redirect_uri=' + monhost
export const registerUrl =
  keycloakUrl +
  '/realms/' +
  keycloakRealm +
  '/protocol/openid-connect/registrations?client_id=franclibre&response_type=code&redirect_uri=' +
  monhost

export const stripePublishableKey =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ??
  'pk_test_51NYnlUJRTTRBxK5hP29bwm48fTPaJOlOaIleo7bSWiiZzRWMN2j8H2tqFSKapl5TQNe0YZQK684d5Lkcv2CkpSyD009wSgUNR6'
