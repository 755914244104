import React from 'react'

import get from 'lodash/get'

import { Table, TableHeader, TableCell, TableBody, DataTableCell } from './GenericTable'

export const GenericTablePDF = ({
  columns,
  data,
  dense,
  headerCellStyle,
  rowCellStyle,
  backgroundColorToAlternate,
  rowBorderColor,
  headerBorderColor,
}) => (
  <Table data={data.filter((item) => !!item.id)}>
    <TableHeader fontSize={!!dense ? 8 : 12} borderColor={headerBorderColor}>
      {React.Children.toArray(
        columns.map(({ exportHeader, Header, PDFHeaderProps: PDFCellProps = {} }) => (
          <TableCell {...PDFCellProps} rowStyle={headerCellStyle}>
            {exportHeader ?? Header}
          </TableCell>
        ))
      )}
    </TableHeader>
    <TableBody
      fontSize={!!dense ? 6 : 10}
      borderColor={rowBorderColor}
      backgroundColorToAlternate={backgroundColorToAlternate}
    >
      {React.Children.toArray(
        columns.map(
          ({
            exportPDFAccessor,
            exportAccessor,
            accessor,
            pdfVariableStyle,
            PDFCellProps = {},
          }) => (
            <DataTableCell
              {...PDFCellProps}
              rowStyle={rowCellStyle}
              getContent={(row) => {
                const cellValue =
                  typeof accessor === 'string' ? get(row, accessor, '') : accessor(row) || ''

                if (exportPDFAccessor) {
                  return typeof exportPDFAccessor === 'string'
                    ? get(row, exportPDFAccessor, '')
                    : exportPDFAccessor(row, cellValue) || ''
                }
                if (exportAccessor) {
                  return typeof exportAccessor === 'string'
                    ? get(row, exportAccessor, '')
                    : exportAccessor(row, cellValue) || ''
                }

                return cellValue
              }}
              getCustomStyle={(row) => {
                const cellValue =
                  typeof accessor === 'string' ? get(row, accessor, '') : accessor(row) || ''
                if (pdfVariableStyle) {
                  return pdfVariableStyle(cellValue)
                }
                return {}
              }}
            />
          )
        )
      )}
    </TableBody>
  </Table>
)
