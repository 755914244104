import { useEffect, useRef } from 'react'

export default function usePrevious(state) {
  const ref = useRef()
  const oldRef = useRef()

  useEffect(() => {
    oldRef.current = ref.current
    ref.current = state
  }, [state])

  return oldRef.current
}
