import { ranks } from '../../data/constants/constants'

export default function RenderFormUser({
  handleSubmit,
  surname,
  name,
  phoneNumber,
  indicatif,
  ss,
  handleChangeSs,
  handleChangeIndicatif,
  handleChangePhoneNumber,
  inputErrors,
  MdGppGood,
  isAdmin,
}) {
  return (
    <form onSubmit={handleSubmit}>
      {/* name et surname inversé en BDD */}
      <label>Nom</label>
      <input value={name?.toUpperCase()} disabled={true} />

      <label>Prénom</label>
      <input value={surname} disabled={true} />

      {/* FORMATAGE VISUEL? */}
      <label>Sécurité sociale</label>
      <input value={ss} onChange={handleChangeSs} disabled={!isAdmin} />
      {inputErrors.ss && <p className="errors-msg">{inputErrors.ss}</p>}

      <label>Numéro de téléphone</label>
      <div style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
        <span>+</span>
        <input
          style={{ minWidth: '0', width: '50px', margin: '0' }}
          value={indicatif}
          onChange={handleChangeIndicatif}
          disabled={!isAdmin}
        />
        <input
          value={phoneNumber}
          onChange={handleChangePhoneNumber}
          style={{ margin: '0' }}
          disabled={!isAdmin}
        />
      </div>
      {inputErrors.phoneNumber && <p className="errors-msg">{inputErrors.phoneNumber}</p>}

      {isAdmin && (
        <button className="valid-btn">
          <p>Modifier</p>
          <MdGppGood />
        </button>
      )}
    </form>
  )
}
