import { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getUserList, deleteUserAccount } from '../../data/services/apiUser'
import {
  getUserSearchByName,
  getUserSearchByAccount,
  getUserSearchByPhone,
} from '../../data/services/apiSearch'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { MdOutlineCreate } from 'react-icons/md'
import {
  AuthContext,
  getTokenIsAdmin,
  getTokenIsController,
  getTokenIsLocalAdmin,
} from '../../utils/providers/AuthContext'
import RenderUser from '../../utils/renders/RenderUser'
import queryString from 'query-string'
import Pagination from '../UI/Pagination'
import UserSearch from '../UI/UserSearch'
import LoadingSpinner from '../UI/LoadingSpinner'
import '../../style/screens/UserList.scss'

const PER_PAGE = 20

export default function UserList() {
  const { isConnected, logOutErrorHandler } = useContext(AuthContext)
  const { pageLoading, setPageLoading } = usePageAuth()
  const [users, setUsers] = useState([])
  const [total, setTotal] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { page = 1, searchCategory, searchValue } = queryString.parse(location.search)
  const [requestedParams, setRequestedParams] = useState()
  // const [page, setPage] = useState(1)
  // const [isBlocked, setIsBlocked] = useState(false)
  // const [deleteAccount, setDeleteAccount] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [isAdmin] = useState(getTokenIsAdmin())

  useEffect(() => {
    initialCheck() // Vérifie le rang et recharge la liste lors de la navigation (voir plus bas)
  }, [])

  useEffect(() => {
    if (
      searchCategory === 'NUMEROCOMPTE' ||
      (requestedParams && searchCategory !== 'NUMEROCOMPTE')
    ) {
      initialCheck()
    }
  }, [searchCategory, searchValue])

  useEffect(() => {
    setTotal(users.filter(filterUser).length)
  }, [location.search, users])

  async function initialCheck() {
    setPageLoading(true)
    const isAllowed = getTokenIsAdmin() || getTokenIsLocalAdmin() || getTokenIsController()

    if (isAllowed) {
      await fetchUsers()
    } else if (isConnected) {
      setErrorMsg('Accès non autorisé.')
      setPageLoading(false)
    }
  }

  async function fetchUsers() {
    setPageLoading(true)
    try {
      const result = await getUserList(
        searchCategory === 'NUMEROCOMPTE' && searchValue ? searchValue : undefined
      )
      setRequestedParams(searchCategory === 'NUMEROCOMPTE' && searchValue ? searchValue : undefined)
      setUsers(result)
      setTotal(result.length)
    } catch (error) {
      setErrorMsg(error.message)
    } finally {
      setPageLoading(false)
    }
  }

  const filterUser = (user) => {
    if (!searchCategory) return true
    if (searchCategory === 'NAME' && user.nom?.toUpperCase().includes(searchValue)) {
      return true
    } else if (searchCategory === 'EMAIL' && user.courriel?.toUpperCase().includes(searchValue)) {
      return true
    } else if (
      searchCategory === 'PHONE' &&
      user['téléphone']?.toUpperCase().includes(searchValue)
    ) {
      return true
    } else if (
      searchCategory === 'SS' &&
      user['numéro_sécu']?.toUpperCase().includes(searchValue)
    ) {
      return true
    } else if (searchCategory === 'RANG' && user['niveau']?.toUpperCase().includes(searchValue)) {
      return true
    } else if (searchCategory === 'NUMEROCOMPTE') return true
    return false
  }

  function setUrlParams(params) {
    const query = queryString.parse(location.search) // analyse paramètre url
    Object.entries(params).forEach(([key, value]) => {
      query[key] = value
    })
    setSearchParams(query)
  }

  function handlePageError() {
    const isAllowed = getTokenIsAdmin() || getTokenIsLocalAdmin()
    if (isAllowed) {
      setErrorMsg('')
    } else {
      navigate('/compte')
    }
  }

  function handleChangePage(n) {
    if (n !== page) {
      setUrlParams({ page: n })
    }
  }

  function handleSearch({ searchCategory, searchValue }) {
    setUrlParams({ page: 1, searchCategory, searchValue: searchValue.toUpperCase() })
  }

  function handleResetSearch() {
    navigate(location.pathname)
  }

  function renderTable() {
    return (
      <div className="table-container">
        {users.length > 0 ? (
          <table>
            <tbody>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Nom</th>
                <th>Prénom</th>
                <th className="hide-responsive">Rang</th>
                <th className="hide-responsive">Email</th>
                <th className="hide-responsive">Ville</th>
                <th className="hide-responsive">Téléphone</th>
                <th className="hide-responsive">SS</th>
                <th className="hide-responsive">Balance</th>
                <th>Actions</th>
              </tr>

              {users
                .filter(filterUser)
                .filter((_, index) => index >= (page - 1) * PER_PAGE && index < PER_PAGE * page)
                .map((user, index) => {
                  return (
                    <RenderUser
                      key={user.id_personne}
                      user={user}
                      index={index}
                      perPage={PER_PAGE}
                      page={page}
                    />
                  )
                })}
            </tbody>
          </table>
        ) : (
          <p className="msg-no-account">Aucun compte ne correspond à la recherche</p>
        )}
      </div>
    )
  }

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button className="back-button-edit" onClick={handlePageError}>
        Retour
      </button>
    </main>
  ) : successMsg ? (
    <main className="page-msg">
      <p>{successMsg}</p>
    </main>
  ) : (
    <>
      <h2>Nos utilisateurs</h2>
      <main className="personnal-profile">
        <div className="table-titles">
          {users.length > 0 && (
            <Pagination onClick={handleChangePage} page={page} total={total} perPage={PER_PAGE} />
          )}
          <UserSearch onSubmit={handleSearch} onReset={handleResetSearch} />
          {renderTable()}

          {users.length > 0 && (
            <Pagination onClick={handleChangePage} page={page} total={total} perPage={PER_PAGE} />
          )}
        </div>
      </main>
    </>
  )
}
