import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { FiSkipBack } from 'react-icons/fi'
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom'
import { ranks } from '../../data/constants/constants'
import {
  clearCaisseCache,
  clearCaisseWithIdCache,
  enregistrerOperateurPersonne,
  getCaisseList,
  getResponsabilites,
  getVerrousCaisseWithId,
} from '../../data/services/apiCaisse'
import { getUserDataSelf } from '../../data/services/apiUser'
import '../../style/screens/CaisseLocale.scss'
import '../../style/screens/UserEdit.scss'
import '../../style/screens/UserList.scss'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import RenderFormCaisseLocaleEnregistrerOperateurPersonne from '../../utils/renders/RenderFormCaisseLocaleEnregistrerOperateurPersonne'
import LoadingSpinner from '../UI/LoadingSpinner'

export const UserDelegation = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { pageLoading, setPageLoading } = usePageAuth()
  const [user, setUser] = useState()
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [reject, setReject] = useState(false)
  const [caisses, setCaisses] = useState([])

  const [verrous, setVerrous] = useState([])
  const [responsabilites, setResponsabilites] = useState([])

  const { codeResp, caisse } = qs.parse(useSearchParams()[0].toString())

  const [selectedCaisse, setSelectedCaisse] = useState(caisse)
  const [selectedResponsabilite, setSelectedResponsabilite] = useState(codeResp)

  useEffect(() => {
    setPageLoading(true)
    getUserDataSelf().then(async (user) => {
      if (user && user.level === ranks.ADMIN) {
        setUser(user)
        try {
          const caisses = await getCaisseList()
          const responsabilites = await getResponsabilites()
          setResponsabilites(responsabilites)
          setCaisses(caisses)
        } catch (e) {
          setErrorMsg('Une erreur est survenue pendant la récupération des caisses')
        }
        setPageLoading(false)
      } else {
        navigate('/compte')
        setPageLoading(false)
      }
    })
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)
    enregistrerOperateurPersonne({
      p_id_personne: id,
      p_code_caisse: selectedCaisse,
      p_code_responsabilité: selectedResponsabilite,
      ...data,
    })
      .then((r) => {
        setSuccessMsg("L'opérateur à bien été enregistré")
        clearCaisseCache(id)
        //navigate('/utilisateur/' + id)
      })
      .catch((e) => setErrorMsg("Une erreur est survenue pendant l'enregistrement de l'opérateur"))
  }

  useEffect(() => {
    if (selectedCaisse) {
      setPageLoading(true)
      getVerrousCaisseWithId(selectedCaisse).then((r) => {
        setVerrous(r.data)
        setPageLoading(false)
      })
    }
  }, [selectedCaisse])

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button
        className="back-button-edit"
        onClick={() => {
          if (reject) {
            window.history.back()
          } else {
            setErrorMsg('')
          }
        }}
      >
        Retour
      </button>
    </main>
  ) : successMsg ? (
    <main className="page-msg">
      <p>{successMsg}</p>
      <button
        className="back-button-edit"
        onClick={() => {
          window.history.back()
        }}
      >
        Retour
      </button>
    </main>
  ) : (
    <>
      <h2>Enregistrement d'un opérateur de caisse : {id}</h2>
      <main className={'caisse-locale'}>
        <button
          className="back-btn"
          onClick={() => {
            window.history.back()
          }}
        >
          <FiSkipBack />
        </button>
        <div className={'edit-verrou'}>
          <RenderFormCaisseLocaleEnregistrerOperateurPersonne
            selectedResponsabilite={selectedResponsabilite}
            setSelectedResponsabilite={setSelectedResponsabilite}
            handleSubmit={onSubmit}
            caisses={caisses}
            selectedCaisse={selectedCaisse}
            setCaisse={setSelectedCaisse}
            id_personne={id}
            verrous={verrous}
            responsabilites={responsabilites}
          />
        </div>
      </main>
    </>
  )
}
