import axios from 'axios'
import queryString from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { FiSkipBack } from 'react-icons/fi'
import { MdGppGood } from 'react-icons/md'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import '../../style/screens/UserEdit.scss'
import { baseUrl } from '../../data/constants/constants'
import { adhHeaders } from '../../data/services/api'
import { registerPret } from '../../data/services/apiUser'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { getTokenIsAdmin } from '../../utils/providers/AuthContext'

export const Subscription = () => {
  const location = useLocation()
  const { id } = useParams()

  const { numeroCompte = '' } = queryString.parse(location.search)
  const { pageLoading, setPageLoading } = usePageAuth()
  const navigate = useNavigate()

  const [isAdmin, setIsAdmin] = useState(false)
  const [reject, setReject] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')

  const [errorMsg, setErrorMsg] = useState('')

  const [paymentTypes, setPaymentTypes] = useState([])
  const [devises, setDevises] = useState([])

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const {
      numeroPret,
      amount,
      giveType,
      monnaie,
      tokenAmount,
      giveTokenType,
      signatureDate,
      clotureDate,
    } = Object.fromEntries(formData)
    try {
      await registerPret(
        numeroCompte,
        numeroPret,
        amount,
        giveType,
        tokenAmount,
        giveTokenType,
        signatureDate,
        clotureDate,
        monnaie
      )
      setSuccessMsg('Le prêt à bien été enregistré')
    } catch (e) {
      setErrorMsg("Une erreur est survenue pendant l'enregistrement du prêt")
    }
  }, [])

  useEffect(() => {
    const isAdminToken = getTokenIsAdmin()
    // console.log({ isAdminToken })
    setIsAdmin(isAdminToken)
    if (!isAdminToken) {
      setReject(true)
      setErrorMsg("Vous n'êtes pas autorisé")
    }
  }, []) // gestion de page

  useEffect(() => {
    setPageLoading(true)
    const promises = [
      axios
        .get(`${baseUrl}/v_moyens_paiement`, { headers: adhHeaders() })
        .then((r) => {
          setPaymentTypes(r.data)
        })
        .catch((e) => {
          setErrorMsg('Une erreur est survenue lors de la récupération des moyens de paiement')
        }),
      axios
        .get(`${baseUrl}/v_devises`, { headers: adhHeaders() })
        .then((r) => {
          setDevises(r.data)
        })
        .catch((e) => {
          setErrorMsg('Une erreur est survenue lors de la récupération des devises')
        }),
    ]
    Promise.all(promises).finally(() => setPageLoading(false))
  }, [])

  return pageLoading ? (
    <main className="page-msg">
      <p>Chargement..</p>
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p className="text-align-c">{errorMsg}</p>
      <button
        className="back-button-edit"
        onClick={() => {
          if (reject) {
            navigate('/compte')
          } else {
            setErrorMsg('')
          }
        }}
      >
        {/* <button className="back-button-edit" onClick={() => navigate('/compte')}> */}
        Retour
      </button>
    </main>
  ) : (
    <>
      <h2>Informations du profil : {id}</h2>
      <main className="personnal-profile">
        <div className="text-blocs">
          <div className="all-blocs">
            <div className="user-edit">
              <div>
                <Link
                  to={
                    '/utilisateur/edit/' +
                    id +
                    '?' +
                    new URLSearchParams(queryString.parse(location.search))
                  }
                >
                  <button className="back-btn">
                    <FiSkipBack />
                  </button>
                </Link>
              </div>
              {successMsg ? (
                <div className="inside-page-msg">
                  <p>{successMsg}</p>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className={'subscriptionForm'}>
                  <label>Numéro compte</label>
                  <input name={'numeroCompte'} value={numeroCompte} disabled />

                  <label>Numéro contrat de prêt</label>
                  <input name={'numeroPret'} required />

                  <label>Montant prêté</label>
                  <input name={'amount'} type={'number'} required step=".01" />

                  <label>Devise de prêt</label>
                  <select name={'monnaie'} required>
                    {devises.map((devise) => (
                      <option value={devise.code_monnaie}>{devise.nom_long}</option>
                    ))}
                  </select>

                  <label>Moyen de versement</label>
                  <select name={'giveType'} required>
                    {paymentTypes.map((paymentType) => (
                      <option value={paymentType.code_m_paiement}>
                        {paymentType.nom_m_paiement}
                      </option>
                    ))}
                  </select>

                  <label>Montant en jeton de gage</label>
                  <input name={'tokenAmount'} type={'number'} required step=".01" />

                  <label>Remise du jeton de garantie</label>
                  <select name={'giveTokenType'} required>
                    <option value="fiduciaire">Fiduciaire</option>
                    <option value="compte de dépôt">Compte de dépôt</option>
                  </select>

                  <label>Date de la signature</label>

                  <input name={'signatureDate'} type={'date'} required />
                  <label>Date de clôture</label>
                  <input name={'clotureDate'} type={'date'} required />

                  <button className="valid-btn">
                    <p>Modifier</p>
                    <MdGppGood />
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
