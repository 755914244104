import axios from 'axios'
import qs from 'qs'
import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { clientSecret, keycloakRealm, keycloakUrl } from '../../data/constants/constants'
import { AuthContext, tokenExists } from './AuthContext'

export const SessionContext = createContext()

function refreshTokenExists() {
  return !!localStorage.getItem('refreshToken')
}

function checkSessionTimeout() {
  // Vérification du délai d'inactivité
  return Date.now() - localStorage.getItem('refreshTokenExpires') < 0
}

function checkActiveSessionTimeout() {
  // Vérification du délai d'inactivité
  return Date.now() - localStorage.getItem('accessTokenExpires') < -5000
}

export function SessionProvider({ children }) {
  const [refreshCountdown, setRefreshCountdown] = useState(-1)
  const { logOut, logOutErrorHandler } = useContext(AuthContext)
  const location = useLocation()
  const [loading, setLoading] = useState(false)

  async function refreshSession() {
    if (tokenExists() && !checkSessionTimeout()) {
      //Session expirée
      logOutErrorHandler({ message: 'TOKEN_EXPIRED' })
    } else if (
      refreshTokenExists() &&
      !checkActiveSessionTimeout() &&
      !loading &&
      !document.hidden
    ) {
      //Rafraichir la session
      setLoading(true)
      try {
        const response = await axios.post(
          `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/token`,
          qs.stringify({
            client_id: 'franclibre',
            client_secret: `${clientSecret}`,
            grant_type: 'refresh_token',
            refresh_token: localStorage.getItem('refreshToken'),
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
        setLoading(false)
        const token = response.data
        localStorage.setItem('accessToken', token.access_token)
        localStorage.setItem('accessTokenExpires', Date.now() + token.expires_in * 1000)
        localStorage.setItem('refreshToken', token.refresh_token)
        localStorage.setItem('refreshTokenExpires', Date.now() + token.refresh_expires_in * 1000)
      } catch (error) {
        logOutErrorHandler({ message: 'TOKEN_EXPIRED' })
        throw error
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      refreshSession()
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <SessionContext.Provider
      value={{
        refreshCountdown,
        setRefreshCountdown,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}
