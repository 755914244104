import { AiOutlineBank } from 'react-icons/ai'
import { BiSolidNetworkChart } from 'react-icons/bi'

export const menuItems = {
  contact: {
    path: '/contact',
    img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/blanc_chat.png',
    name: 'Contact',
  },
  /*currency: {
    path: '/monnaie',
    img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/blanc_monnaie.png',
    name: 'Monnaie',
  },*/
  account: {
    path: '/compte',
    img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/blanc_synthese.png',
    name: 'Synthèse',
  },
  operation: {
    path: '/operation',
    img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/blanc_curseurs.png',
    name: 'Opérations',
  },
  beneficiaries: {
    path: '/beneficiaires',
    img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/blanc_compte.png',
    name: 'Bénéficiaires',
  },
  shop: {
    path: '/enseignes',
    img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/blanc_in_situ.png',
    name: 'Enseignes',
  },
  userlist: {
    path: '/liste-utilisateurs',
    img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/blanc_home.png',
    name: 'Profils',
  },
  prets: {
    path: '/prets',
    img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/blanc_cb.png',
    name: 'Prêts',
  },
  caisseLocale: {
    path: '/caisse-locale',
    img: <AiOutlineBank size={25} />,
    name: 'Caisse locale',
  },
  delegation: {
    path: '/delegation',
    img: <BiSolidNetworkChart size={25} />,
    name: 'Délégation',
  },
}
