import { MdGppGood } from 'react-icons/md'
import { useSMSVerificationContext } from '../providers/SMSVerificationContext'

export const RenderSMSVerificationForm = ({ handleSubmit, formRef }) => {
  const { inVerification } = useSMSVerificationContext()

  if (!inVerification) return null

  return (
    <form onSubmit={handleSubmit} method={'POST'} ref={formRef}>
      <label style={{ textAlign: 'center' }}>Code reçu par SMS</label>
      <input name={'code'} type={'number'} required />

      <button className="valid-btn deposit" type={'submit'}>
        <p>Valider</p>
        <MdGppGood />
      </button>
    </form>
  )
}
