import { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { getUserDataSelf } from '../../data/services/apiUser'
import { AuthContext, getTokenIsAdmin } from '../../utils/providers/AuthContext'
import { currencySymbol, currencyText, ranks } from '../../data/constants/constants'
import { exchangeRate, getActifs } from '../../data/services/apiTransaction'
import { menuItems } from '../../data/constants/navMenuItems'
import '../../style/UI/UserBanner.scss'

export default function UserBanner() {
  const [exchangeRateFr, setExchangeRateFr] = useState(0)
  const [actifs, setActifs] = useState([])
  const [user, setUser] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLocalAdmin, setIsLocalAdmin] = useState(false)
  const location = useLocation()
  const { logOut } = useContext(AuthContext)

  useEffect(() => {
    async function fetchUser() {
      try {
        const data = await getUserDataSelf(logOut)
        setIsAdmin(data.level === ranks.ADMIN)
        //setIsLocalAdmin(data.level === ranks.LOCAL_ADMIN)
        setUser(data)
      } catch (error) {
        throw error
      }
    }
    fetchUser()
  }, []) // location.pathname si on veut rafraichir le nom en temps réel (fetch à chaque navigation)

  useEffect(() => {
    async function fetch() {
      try {
        const result = await exchangeRate()
        setExchangeRateFr(result)
        if (getTokenIsAdmin()) {
          const actifsResult = await getActifs()
          setActifs(actifsResult)
        }
      } catch (error) {
        throw error
      }
    }
    fetch()
  }, [location.pathname])

  function renderMenuItem(el, index) {
    return (
      <li key={index} className="items">
        <Link to={el.path} className="items-nav">
          <img src={el.img} height="25px" alt="icon" />
        </Link>
      </li>
    )
  }

  return (
    <div className="user-container">
      {user && (
        <div className="user-flex">
          <span className="user-info">
            {/*Cotation : EUR/{currencyText} <strong>{exchangeRateFr}€</strong>/
            <strong>1{currencySymbol}</strong>*/}
          </span>

          {isAdmin && (
            <div className={'actifs'}>
              Actifs circulants : {actifs}
              {currencySymbol}
            </div>
          )}

          <div className="user-flex-data">
            {(isAdmin || isLocalAdmin) && [menuItems.userlist].map(renderMenuItem)}
            {[menuItems.shop].map(renderMenuItem)}

            {user && (
              <span className="welcome span-none">Bienvenue {user.name + ' ' + user.surname}</span>
            )}

            {[/*menuItems.currency, */ menuItems.contact].map(renderMenuItem)}
          </div>
        </div>
      )}
    </div>
  )
}
