import { MdGppGood } from 'react-icons/md'

export default function RenderFormCaisseLocaleEnregistrerOperateur({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <label>Numéro de compte</label>
      <input name={'numéro_compte'} type={'text'} required />

      <button className="valid-btn deposit" type={'submit'}>
        <p>Enregistrement</p>
        <MdGppGood />
      </button>
    </form>
  )
}
