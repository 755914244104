import moment from 'moment'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { BsChevronRight } from 'react-icons/bs'
import { MdOutlineDelete } from 'react-icons/md'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { deletePret, getPretList } from '../../data/services/apiUser'
import '../../style/screens/UserList.scss'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import {
  AuthContext,
  getTokenIsAdmin,
  getTokenIsLocalAdmin,
} from '../../utils/providers/AuthContext'
import { ConfirmPopUp } from '../UI/ConfirmPopUp'
import LoadingSpinner from '../UI/LoadingSpinner'
import { PretSearch } from '../UI/PretSearch'

export default function PretList() {
  const { isConnected, logOutErrorHandler } = useContext(AuthContext)
  const { pageLoading, setPageLoading } = usePageAuth()
  const [prets, setPrets] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()
  const { searchCategory, searchValue } = queryString.parse(location.search)
  const [successMsg, setSuccessMsg] = useState('')
  const [isAdmin] = useState(getTokenIsAdmin())

  const [deleteConfirmPretId, setDeleteConfirmPretId] = useState(null)

  useEffect(() => {
    initialCheck() // Vérifie le rang et recharge la liste lors de la navigation (voir plus bas)
  }, [location.search])

  function setUrlParams(params) {
    const query = queryString.parse(location.search) // analyse paramètre url
    Object.entries(params).forEach(([key, value]) => {
      query[key] = value
    })
    setSearchParams(query)
  }

  function handleSearch({ searchCategory, searchValue }) {
    setUrlParams({ searchCategory, searchValue: searchValue.toUpperCase() })
  }

  function handleResetSearch() {
    navigate(location.pathname)
  }

  async function initialCheck() {
    setPageLoading(true)
    const isAllowed = getTokenIsAdmin() || getTokenIsLocalAdmin()

    if (isAllowed) {
      await fetchUsers()
    } else if (isConnected) {
      setErrorMsg('Accès non autorisé.')
      setPageLoading(false)
    }
  }

  async function fetchUsers() {
    setPageLoading(true)
    try {
      const result = await getPretList()
      setPrets(result)
    } catch (error) {
      setErrorMsg(error.message)
    } finally {
      setPageLoading(false)
    }
  }

  function handlePageError() {
    const isAllowed = getTokenIsAdmin() || getTokenIsLocalAdmin()
    if (isAllowed) {
      setErrorMsg('')
    } else {
      navigate('/compte')
    }
  }

  const filterPret = (pret) => {
    if (!searchCategory) return true
    if (searchCategory === 'NUMERO' && pret['numéro_prêt']?.toUpperCase().includes(searchValue)) {
      return true
    } else if (searchCategory === 'NOM_PERSONNE' && pret.nom?.toUpperCase().includes(searchValue)) {
      return true
    }
    return false
  }

  function renderTable() {
    return (
      <div className="table-container">
        {prets.length > 0 ? (
          <table>
            <tbody>
              <tr>
                <th>#</th>
                <th>N°</th>
                <th>Nom personne</th>
                <th>Montant</th>
                <th>Date de création</th>
                <th>Date de fin</th>
                <th>Versement</th>
                <th>Devise</th>
                <th>Actions</th>
              </tr>

              {prets.filter(filterPret).map((pret, index) => {
                const onClick = () => {
                  navigate('/utilisateur/' + pret.id_personne)
                }
                return (
                  <tr className={'clickableRow'}>
                    <th onClick={onClick}>{index}</th>
                    <td onClick={onClick}>{pret.numéro_prêt}</td>
                    <td onClick={onClick}>{pret.nom}</td>
                    <td onClick={onClick}>{pret.montant_emprunt}</td>
                    <td onClick={onClick}>{moment(pret.date_création).format('DD/MM/YYYY')}</td>
                    <td onClick={onClick}>{moment(pret.date_fin).format('DD/MM/YYYY')}</td>
                    <td onClick={onClick}>{pret.nom_m_paiement}</td>
                    <td onClick={onClick}>{pret.devise ?? ''}</td>
                    <td className="flex-actions" style={{ cursor: 'auto' }}>
                      <button
                        className="view-btn"
                        onClick={(e) => setDeleteConfirmPretId(pret.numéro_prêt)}
                      >
                        Supprimer
                        <AiOutlineDelete size={16} />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <p className="msg-no-account">Aucun prêt trouvé</p>
        )}
      </div>
    )
  }

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button className="back-button-edit" onClick={handlePageError}>
        Retour
      </button>
    </main>
  ) : successMsg ? (
    <main className="page-msg">
      <p>{successMsg}</p>
      <button
        className="back-button-edit"
        onClick={() => {
          setSuccessMsg('')
          setDeleteConfirmPretId(null)
        }}
      >
        Retour
      </button>
    </main>
  ) : (
    <>
      {deleteConfirmPretId && (
        <ConfirmPopUp
          title={'Êtes-vous sûr de vouloir supprimer le prêt n°' + deleteConfirmPretId + ' ?'}
          onCancel={() => setDeleteConfirmPretId(null)}
          onConfirm={() => {
            setPageLoading(true)
            deletePret(deleteConfirmPretId)
              .then((r) => {
                setSuccessMsg('Le prêt n°' + deleteConfirmPretId + ' a bien été supprimé')
                fetchUsers()
              })
              .catch((e) => {
                setErrorMsg('Une erreur est survenue pendant la suppression du prêt')
                setDeleteConfirmPretId(null)
              })
              .finally(() => setPageLoading(false))
          }}
        />
      )}
      <h2>Prêts</h2>
      <main className="personnal-profile">
        <div className="table-titles">
          <PretSearch onSubmit={handleSearch} onReset={handleResetSearch} />
          {renderTable()}
        </div>
      </main>
    </>
  )
}
