import '../../style/UI/PopUpLogOut.scss'
import moment from 'moment'
import { useMemo, useState } from 'react'
import 'moment/locale/fr'

const FIRST_YEAR = 2022

moment.locale('fr')

export const PopUpOperationsExport = ({ onClose, download, self, user }) => {
  const [selected, setSelected] = useState(moment().startOf('month').format('MM/DD/YYYY'))
  const months = useMemo(() => {
    const months = []
    for (let i = FIRST_YEAR; i <= moment().year(); i++) {
      for (let j = 1; j <= 12; j++) {
        const date = moment(j + '/01/' + i)
        if (date.isBefore(moment())) {
          months.push(date)
        }
      }
    }
    return months.reverse()
  }, [])

  return (
    <>
      <div className="popup-overlay" onClick={onClose}></div>
      <div className="popup-container">
        <p className="popup-container__msg">
          {self ? 'Exporter mes opérations' : 'Exporter les opérations de ' + user.name}
        </p>
        <div className="popup-container__button">
          <select value={selected} onChange={(e) => setSelected(e.target.value)}>
            {months.map((month) => (
              <option key={month.format()} value={month.format('MM/DD/YYYY')}>
                {month.format('MMMM YYYY')}
              </option>
            ))}
          </select>
          <button
            onClick={() =>
              download(moment(selected).toDate(), moment(selected).endOf('month').toDate())
            }
          >
            Télécharger
          </button>
        </div>
      </div>
    </>
  )
}
