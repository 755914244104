import { MdGppGood } from 'react-icons/md'

export default function RenderFormCaisseLocaleEnregistrerDepot({ handleSubmit, min, max }) {
  return (
    <form onSubmit={handleSubmit}>
      <label>Numéro de compte</label>
      <input name={'p_num_compte'} type={'text'} required />

      <label>Montant</label>
      <input name={'p_montant'} type={'number'} required min={min} max={max} />

      <button className="valid-btn deposit" type={'submit'}>
        <p>Enregistrement</p>
        <MdGppGood />
      </button>
    </form>
  )
}
