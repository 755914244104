import React, { useEffect, useState } from 'react'
import {
  clearTransactionsCache,
  createTransaction,
  getSelfUserTransactions,
} from '../../data/services/apiTransaction'
import { clearUserCache, getUserDataSelf } from '../../data/services/apiUser'
import '../../style/UI/Transaction.scss'
import { forceInputNumber } from '../../utils/functions/forceInput'
import { useSMSVerificationContext } from '../../utils/providers/SMSVerificationContext'

export default function Transaction({ refresh }) {
  const [user, setUser] = useState([])
  const [transactions, setTransactions] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [inputErrors, setInputErrors] = useState({})
  const [openTransactions, setOpenTransactions] = useState(false)
  const [account, setAccount] = useState('')
  const [name, setName] = useState('')
  const [amount, setAmount] = useState(0)
  const [success, setSuccess] = useState(false)

  const { RenderSMSVerification, inVerification, setInVerification } = useSMSVerificationContext()

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    try {
      let result
      result = await getUserDataSelf()
      setUser(result)
      result = await getSelfUserTransactions()
      setTransactions(result)
    } catch (error) {
      setErrorMsg(error.message)
    }
  }

  const handleChangeAccount = (e) => setAccount(e.target.value.substring(0, 24))
  const handleChangeName = (e) => setName(e.target.value.substring(0, 30))
  const handleChangeAmount = (e) => setAmount(+parseFloat(e.target.value).toFixed(2))

  function handleOpenTransactions() {
    if (openTransactions === false) {
      setSuccess(false)
    }
    setOpenTransactions(!openTransactions)
  }

  function canSubmit() {
    const errors = {
      account: '',
      name: '',
      amount: '',
    }
    if (account === '') {
      errors.account = 'Ce champ ne doit pas être vide'
    }
    if (name === '') {
      errors.name = 'Ce champ ne doit pas être vide'
    }
    if (amount <= 0) {
      errors.amount = 'Le montant doit être supérieur à zéro'
    }
    setInputErrors(errors)
    let success = Object.values(errors).every((el) => el.length === 0) // passe toutes les valeurs en vérifiant que ce sont des bien chaînes de caractère vides (bouléen vrai)
    return success
  }

  const onSuccess = async () => {
    setSuccess(true)
    setAccount('')
    setName('')
    setAmount(0)
    setOpenTransactions(false)
    /*setTimeout(() => {
      window.location.reload()
    }, 1100)*/
    clearTransactionsCache()
    clearUserCache()
    refresh()
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (!canSubmit()) return
    try {
      const result = await createTransaction(user.accountNumber, account, amount, name)
      if (result === 'SMS') {
        setInVerification(true)
        return
      }
      await onSuccess()
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.code === 'P0001') {
        inputErrors.amount = error?.response?.data?.message
        setInputErrors(inputErrors)
      }
      if (error) throw error.messsage
    }
  }

  return (
    <div>
      {/* <h3 className="text-align-c">Envoyer un paiement à un tiers</h3> */}
      {inVerification ? (
        <RenderSMSVerification onSuccess={onSuccess} />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
            <button
              onClick={handleOpenTransactions}
              className="transa-btn"
              style={{ marginLeft: '0', marginRight: '0' }}
            >
              {openTransactions ? (
                <span>Annuler le paiement en cours</span>
              ) : (
                <span>Envoyer un paiement</span>
              )}
            </button>
          </div>

          {openTransactions && (
            <form className="transa-form">
              <label>N° du compte à créditer</label>
              <input value={account} onChange={handleChangeAccount} />
              {inputErrors.account && <p className=" text-align-ce">{inputErrors.account}</p>}
              <label>Libellé du virement</label>
              <input value={name} onChange={handleChangeName} />
              {inputErrors.name && <p className=" text-align-ce">{inputErrors.name}</p>}
              <label>Montant à émettre</label>
              <input value={amount} onChange={handleChangeAmount} step=".01" type={'number'} />
              {inputErrors.amount && <p className=" text-align-ce">{inputErrors.amount}</p>}
              {/* {inputErrors.amount ||
            (amount <= 0 && <p className=" text-align-ce">{inputErrors.amount}</p>)} */}
              <button onClick={handleSubmit} className="transa-btn">
                Validation
              </button>
            </form>
          )}
          {success && <p className="text-align-c green">Votre don d'argent a bien été effectué</p>}
        </>
      )}
    </div>
  )
}
