export default class Shop {
  id: string
  name: string | null
  position: [number, number]
  phoneNumber: string | null
  email?: string
  info: string
  activity: string
  comment: string | null

  constructor(data: any) {
    this.id = data.latitude + data.longitude
    this.position = [parseFloat(data.latitude), parseFloat(data.longitude)]
    this.info = data.rue + ' ' + data.ville + ' ' + data.code_postal
    this.name = data.raisonsociale_personnemorale
    this.phoneNumber = data.téléphone_personne
    this.activity = data.activité_adresse
    this.comment = data.commentaire_adresse
  }
}
