import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AjouterBeneficiaire from './component/screens/AjouterBeneficiaire'
import { CaisseLocale } from './component/screens/CaisseLocale'
import { Delegation } from './component/screens/Delegation'
import PretList from './component/screens/PretList'
import { Subscription } from './component/screens/Subscription'
import { UserAccount } from './component/screens/UserAccount'
import { UserDelegation } from './component/screens/UserDelegation'
import UserDeposit from './component/screens/UserDeposit'
import { AuthProvider } from './utils/providers/AuthContext'
import { SessionProvider } from './utils/providers/SessionProvider'
import Layout from './component/layout/Layout'
import Error from './component/layout/Error'
import Connection from './component/screens/Connection'
import Account from './component/screens/Account'
import Operation from './component/screens/Operation'
import Beneficiaries from './component/screens/Beneficiaries'
import UserEdit from './component/screens/UserEdit'
import UserList from './component/screens/UserList'
import Currency from './component/screens/Currency'
import Contact from './component/screens/Contact'
import Shop from './component/screens/Shop'
import './style/variable/Theme.scss'
import './App.scss'
import { SMSVerificationProvider } from './utils/providers/SMSVerificationContext'

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SessionProvider>
          <SMSVerificationProvider>
            <Routes>
              <Route element={<Layout />}>
                {/* Users */}
                <Route index path="/" element={<Connection />} />
                <Route path="/compte" element={<Account />} />
                <Route path="/operation" element={<Operation />} />
                <Route path="/beneficiaires" element={<Beneficiaries />} />
                <Route path="/enseignes" element={<Shop />} />
                {/* Adds */}
                <Route path="/contact" element={<Contact />} />
                <Route path="/mentions-legales" element={<Contact mentions />} />
                {/*<Route path="/monnaie" element={<Currency />} />*/}
                <Route path={'/ajout_beneficiaire'} element={<AjouterBeneficiaire />} />
                {/* Admins */}
                <Route path="/liste-utilisateurs" element={<UserList />} />
                <Route path="/prets" element={<PretList />} />
                <Route path="/utilisateur/edit/:id" element={<UserEdit />} />
                <Route path="/utilisateur/:id" element={<UserAccount />} />
                <Route path="/utilisateur/:id" element={<UserAccount />} />
                <Route path="/utilisateur/:id/delegation" element={<UserDelegation />} />
                <Route path="/utilisateur/:id/operation" element={<Operation self={false} />} />
                <Route path="/depot/:id" element={<UserDeposit />} />
                <Route path="/souscription-pret/:id" element={<Subscription />} />
                <Route path="/caisse-locale" element={<CaisseLocale />} />
                <Route path="/delegation" element={<Delegation />} />
                <Route path="/delegation/:id" element={<CaisseLocale />} />
                <Route path="/delegation/:id/:codeResp" element={<CaisseLocale />} />
                {/* Errors */}
                <Route path="*" element={<Error />} />
              </Route>
            </Routes>
          </SMSVerificationProvider>
        </SessionProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}
