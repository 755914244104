import '../../style/UI/Pagination.scss'

export default function Pagination({ onClick, page, total, perPage = 50 }) {
  function getMaxPage() {
    return Math.ceil(total / perPage) // arrondi au plafond
  }

  function handleClick(e) {
    e.preventDefault()
    onClick(e.target.value)
  }

  function getPageButtons() {
    const el = []
    if (page > 1) {
      el.push(
        <button className="previous-button" key="previous" value={page - 1} onClick={handleClick}>
          {'<'}
        </button>
      )
    }
    for (let i = 1; i <= getMaxPage(); i++) {
      if (i === parseInt(page)) {
        // show current page
        el.push(
          <button className="actual-button" key={i} value={i} onClick={handleClick}>
            {i}
          </button>
        )
      } else if (i === 1 || i === getMaxPage() || Math.abs(i - page) <= 1) {
        // afficher écart des pages
        el.push(
          <button key={i} value={i} onClick={handleClick}>
            {i}
          </button>
        )
      } else {
        if (el[el.length - 1] !== null) {
          // sinon null et affiche triple point
          el.push(null)
        }
      }
    }
    if (page < getMaxPage()) {
      el.push(
        <button className="next-button" key="next" value={parseInt(page) + 1} onClick={handleClick}>
          {'>'}
        </button>
      )
    }
    return el
  }

  return (
    <div className="pagination-container">
      {getPageButtons().map((button, index) => {
        if (button === null) {
          return (
            <span className="pagination-span" key={'ellipsis' + index}>
              …
            </span>
          )
        }
        return button
      })}
    </div>
  )
}
