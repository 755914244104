import { useState } from 'react'
import '../../style/screens/Contact.scss'

export default function Contact({ mentions }) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [, setShowErrors] = useState(false)
  const [, setErrorMsgs] = useState([])
  const [, setMailSent] = useState(false)

  function clearForm() {
    setName('')
    setEmail('')
    setMessage('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setErrorMsgs([])
    setMailSent(false)

    let isValidEmail = false
    if (!name) {
      setErrorMsgs((errorMsgs) => [...errorMsgs, 'Le nom est obligatoire.'])
    }
    if (!email) {
      setErrorMsgs((errorMsgs) => [...errorMsgs, 'Le courriel est obligatoire.'])
    } else {
      if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
        setErrorMsgs((errorMsgs) => [...errorMsgs, 'Invalid email address.'])
      } else {
        isValidEmail = true
      }
    }
    if (!message) {
      setErrorMsgs((errorMsgs) => [...errorMsgs, 'Un message est obligatoire.'])
    }
    if (!name || !email || !isValidEmail || !message) {
      setShowErrors(true)
      return false
    } else {
      setShowErrors(false)
      // Use API here
      setMailSent(true)
      clearForm()
    }
  }

  return (
    <>
      <main className="contact-page">
        <form
          className="cmcfui1 text-blocs"
          action=""
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          autoComplete="off"
        >
          <h3 className="cmhead">{mentions ? 'Mentions légales' : 'Contactez-nous'}</h3>
          <p>
            LA FINANCIERE LIBRE <br />
            Esplanade de la Liberté,
            <br />
            1254, Genève
          </p>
          <p>
            <a href="mailto:contact@lafinancierelibre.ch">contact@lafinancierelibre.ch</a>
          </p>
          <p>
            Nous n’acceptons aucune transaction financière. Ce site n’est pas un site marchand ou
            connecté à une application marchande.
          </p>
          <p>
            Ce site expérimental constitue une simple interface de test d’un instrument de partage
            de données numériques et d’unités de valeur entre développeurs et programmeurs
            passionnés par l’exploration de nouvelles méthodes de paiements.
          </p>
        </form>
      </main>
    </>
  )
}
