import { baseUrl } from '../constants/constants'
import axios from 'axios'
import { adhHeaders } from './api'
import { clearUserCache } from './apiUser'

export const createBeneficiary = async (body) => {
  try {
    const response = await axios.post(`${baseUrl}/rpc/mémoriser_compte`, body, {
      headers: adhHeaders(true),
    })
    if (response.data.error) {
      console.log('Erreur envoyée par le serveur', response.data.error)
    }
    if (response.data[0].code === 'SMS Envoyé') {
      return 'SMS'
    }
    clearUserCache()
    return response
  } catch (error) {
    throw error
  }
} // POST

export const deleteBeneficiary = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}/rpc/oublier_compte`,
      { numéro_compte_cible: id },
      {
        headers: adhHeaders(true),
      }
    )
    clearUserCache()
    return response
  } catch (error) {
    throw error
  }
} // DELETE
