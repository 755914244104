import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { FiSkipBack } from 'react-icons/fi'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { registerUserDepositAchat, registerUserDepositDepot } from '../../data/services/apiUser'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import RenderFormDeposit from '../../utils/renders/RenderFormDeposit'
import '../../style/screens/UserEdit.scss'

export default function UserDeposit() {
  const { id } = useParams()
  const location = useLocation()
  const { email, type } = queryString.parse(location.search)
  const [searchParams, setSearchParams] = useSearchParams()
  const { pageLoading } = usePageAuth()

  const [reject, setReject] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')

  const navigate = useNavigate()

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const {
      amount,
      email,
      transactionId,
      type: achatType,
      operationType,
    } = Object.fromEntries(formData)
    try {
      if (type === 'achat') {
        await registerUserDepositAchat(email, amount, transactionId, achatType, operationType)
      } else {
        await registerUserDepositDepot(email, amount)
      }
      setSuccessMsg('Le dépôt à bien été effectué')
    } catch (e) {
      setErrorMsg("Une erreur est survenue pendant l'envoi du dépôt")
    }
  }

  useEffect(() => {
    if (type && type !== 'achat' && type !== 'depot') {
      searchParams.delete('type')
      setSearchParams(searchParams)
    }
  }, [type])

  useEffect(() => {
    setSuccessMsg('')
  }, [location])

  if (pageLoading) {
    return (
      <main className="page-msg">
        <p>Chargement..</p>
      </main>
    )
  }

  if (errorMsg) {
    return (
      <main className="page-msg">
        <p className="text-align-c">{errorMsg}</p>
        <button
          className="back-button-edit"
          onClick={() => {
            if (reject) {
              navigate('/compte')
            } else {
              setErrorMsg('')
            }
          }}
        >
          Retour
        </button>
      </main>
    )
  }

  if (!type) {
    return (
      <>
        <h2>Dépôt utilisateur: {id}</h2>
        <main className="caisse-locale">
          <Link to="/liste-utilisateurs">
            <button className="back-btn">
              <FiSkipBack />
            </button>
          </Link>
          <div className={'enregistrement'}>
            <div
              onClick={() => {
                searchParams.set('type', 'achat')
                setSearchParams(searchParams)
              }}
            >
              Achat/Vente
            </div>
            <div
              onClick={() => {
                searchParams.set('type', 'depot')
                setSearchParams(searchParams)
              }}
            >
              Dépôt/Retrait
            </div>
          </div>
        </main>
      </>
    )
  }

  return (
    <>
      <h2>Dépôt utilisateur: {id}</h2>
      <main className="personnal-profile">
        <div className="text-blocs">
          <div className="all-blocs">
            <div className="user-edit">
              <div>
                <button
                  className="back-btn"
                  onClick={() => {
                    searchParams.delete('type')
                    setSearchParams(searchParams)
                  }}
                >
                  <FiSkipBack />
                </button>
                {successMsg ? (
                  <div className="inside-page-msg">
                    <p>{successMsg}</p>
                  </div>
                ) : (
                  <RenderFormDeposit
                    handleSubmit={onSubmit}
                    defaultEmail={email}
                    type={type}
                    setErrorMsg={setErrorMsg}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
