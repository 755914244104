import qs from 'qs'
import { getTokenAccount, getTokenIsAdmin, tokenExists } from '../../utils/providers/AuthContext'
import { baseUrl, keycloakRealm, keycloakUrl, ranks, clientSecret } from '../constants/constants'
import axios from 'axios'
import User from '../models/User.ts'
import { adhHeaders, adminHeaders, cache, controleurHeaders, getFromCache } from './api'

let cacheObj = {}

export const clearUserCache = () => {
  cacheObj = {}
}

export const getUserDataAdmin = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}api/admin/account/${id}/`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return new User(response.data)
  } catch (error) {
    throw error
  }
}

export const getUserDataSelf = () =>
  cache(cacheObj, 'user', async () => {
    try {
      if (!tokenExists()) {
        return
      }
      let personne
      const getPersonne = async () => {
        return (await axios.get(`${baseUrl}/v_personne`, { headers: adhHeaders() })).data[0]
      }
      try {
        personne = await getPersonne()
      } catch (e) {
        if (e?.response?.status === 400) {
          await axios.post(`${baseUrl}/rpc/touch_personne`, {}, { headers: adhHeaders(true) })
        }
        personne = await getPersonne()
      }
      const accounts = (await axios.get(`${baseUrl}/v2_soldes`, { headers: adhHeaders() })).data
      const contacts = (
        await axios.get(`${baseUrl}/v_comptes_mémorisés`, { headers: adhHeaders() })
      ).data
      const role = (await axios.get(`${baseUrl}/v_rôle_adhérent`, { headers: adhHeaders() }))
        .data?.[0]

      const prets = (await axios.get(`${baseUrl}/v_prêts`, { headers: adhHeaders() })).data

      const keycloakCredentials = await getUserKeycloakCredentials()
      const otpCredentials = keycloakCredentials?.find((credentials) => credentials.type === 'otp')
      const otp = otpCredentials?.userCredentialMetadatas?.[0]?.credential

      const token = getTokenAccount()

      const solde = accounts[0]

      return new User({
        accounts: accounts.slice(1),
        id: solde?.id_compte ?? 'id',
        accountNumber: solde?.id_compte,
        numeroCompte: solde?.numéro_compte,
        email: personne.courriel,
        indicatif: personne['indicatif'],
        phoneNumber: personne['téléphone'],
        ss: personne['numéro_sécu'],
        name: personne['prénom'] ?? token.given_name,
        surname: personne['nom'] ?? token.family_name,
        level: getTokenIsAdmin() ? ranks.ADMIN : ranks.USER,
        balance: solde?.solde ?? 0,
        contacts: contacts.map((contact) => ({
          id: contact['id_compte'],
          numero_compte: contact['numéro_compte'],
          name: contact['nom'],
        })),
        prets: prets ?? [],
        caisse: role
          ? {
              code: role.code_caisse,
              nom: role.nom_caisse,
              role: role.code_responsabilité,
              libelleRole: role.libelleRole,
              date_debut: role.date_début,
              date_fin: role.date_fin,
            }
          : null,
        adresse: personne.ligne1
          ? {
              ligne1: personne.ligne1,
              cp: personne.cp,
              ville: personne.ville,
            }
          : null,
        otp,
      })
    } catch (error) {
      if (error.response?.status === 403) {
        throw new Error('TOKEN_EXPIRED')
      } else {
        throw error
      }
    }
  })

export const getUser = (id) =>
  cache(cacheObj, 'user_' + id, async () => {
    const prets = (
      await axios.get(`${baseUrl}/v_lprêts?id_personne=eq.${id}`, { headers: adminHeaders() })
    ).data
    const accounts = (
      await axios.get(`${baseUrl}/v2_lsoldes?id_personne=eq.${id}`, { headers: adminHeaders() })
    ).data
    const solde = accounts[0]
    const compte = (
      await axios.get(`${baseUrl}/v_lcomptes?id_personne=eq.${id}`, { headers: adminHeaders() })
    ).data
    const personne = compte[0]
    return new User({
      accounts: [],
      id,
      accountNumber: solde?.id_compte,
      numeroCompte: solde?.numéro_compte,
      balance: solde?.solde ?? 0,
      email: personne.courriel,
      indicatif: personne['indicatif'],
      phoneNumber: personne['téléphone'],
      ss: personne['numéro_sécu'],
      name: personne['prénom'],
      surname: personne['nom'],
      prets:
        prets?.map((pret) => ({
          ...pret,
          numéro: pret['numéro_prêt'],
          m_versement: pret.nom_m_paiement,
          montant: pret.montant_emprunt,
        })) ?? [],
    })
  })

// export const getUserLevel = async (id) => {
//   try {
//     const response = await axios.get(`${baseUrl}api/account/${id}/`, {
//       headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
//     })
//     return response
//   } catch (error) {
//     throw error
//   }
// }

export const getUserContact = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}api/contact/${id}`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getUserList = async (numeroCompte = undefined) => {
  try {
    return (
      await axios.get(
        `${baseUrl}/v_lcomptes${numeroCompte ? '?numéro_compte=like.' + numeroCompte : ''}`,
        {
          headers: adminHeaders(),
        }
      )
    ).data
  } catch (error) {
    throw error
  }
}

export const getPretList = async () => {
  try {
    return (await axios.get(`${baseUrl}/v_lprêts`, { headers: adminHeaders() })).data
  } catch (error) {
    throw error
  }
}

export const deletePret = async (p_numéro_prêt) => {
  const response = await axios.post(
    `${baseUrl}/rpc/supprimer_prêt`,
    {
      p_numéro_prêt,
    },
    {
      headers: adminHeaders(true),
    }
  )
  return response.data
}

// POST & PUT & Delete
export const loginUser = async (username, password, otp) => {
  try {
    const response = await axios.post(
      `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/token`,
      qs.stringify({
        username,
        password,
        client_id: 'franclibre',
        client_secret: `${clientSecret}`,
        grant_type: 'password',
        scope: 'openid',
        ...(otp ? { totp: otp } : {}),
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    return response.data
  } catch (error) {
    throw error
  }
} // POST at connexion

export const getUserInfo = async () => {
  try {
    const response = await axios.get(
      `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/userinfo`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getUserKeycloakCredentials = async () => {
  try {
    const response = await axios.get(`${keycloakUrl}/realms/${keycloakRealm}/account/credentials`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const deleteUserKeycloakCredentials = async (id) => {
  try {
    const response = await axios.delete(
      `${keycloakUrl}/realms/${keycloakRealm}/account/credentials/${id}`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const createUser = async (body) => {
  try {
    const response = await axios.post(`${baseUrl}api/admin/accounts/`, body, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    if (response.data.error) {
      console.log('Erreur envoyée par le serveur', response.data.error)
    }
    return response
  } catch (error) {
    throw error
  }
} // POST

export const editUserSS = async (id_personne, ss) => {
  return (
    await axios.post(
      `${baseUrl}/rpc/set_numesecu`,
      { p_id_personne: id_personne, p_numéro_ss: ss },
      { headers: adminHeaders(true) }
    )
  ).data
}

export const editUserPhone = async (id_personne, indicatif, phoneNumber) => {
  return (
    await axios.post(
      `${baseUrl}/rpc/set_numtéléphone`,
      { p_id_personne: id_personne, p_indicatif_tel: '+' + indicatif, p_numéro_tel: phoneNumber },
      { headers: adminHeaders(true) }
    )
  ).data
}

export const changeUserAccount = async (id, body) => {
  try {
    const response = await axios.put(`${baseUrl}api/admin/accounts/${id}/`, body, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return response
  } catch (error) {
    throw error
  }
} // PUT

export const deleteUserAccount = async (id) => {
  try {
    const response = await axios.delete(`${baseUrl}api/admin/account/${id}/`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return response
  } catch (error) {
    throw error
  }
} // DELETE

export const registerUserDepositAchat = async (
  email,
  amount,
  transactionId,
  type,
  operationType
) => {
  return (
    await axios.post(
      `${baseUrl}/rpc/enregistrer_achat_fl_esp`,
      {
        p_courriel_personne: email,
        p_montant: parseInt(amount),
        p_num_transaction: transactionId,
        p_typeversement: type,
        p_typeopération: operationType,
      },
      { headers: adminHeaders(true) }
    )
  ).data
}

export const registerUserDepositDepot = async (email, amount) => {
  return (
    await axios.post(
      `${baseUrl}/rpc/saisir_dépôt_fl`,
      {
        p_courriel_personne: email,
        p_montant: parseInt(amount),
      },
      { headers: adminHeaders(true) }
    )
  ).data
}

export const registerPret = async (
  numeroCompte,
  numeroPret,
  amount,
  giveType,
  tokenAmount,
  giveTokenType,
  signatureDate,
  clotureDate,
  monnaie
) => {
  return (
    await axios.post(
      `${baseUrl}/rpc/enregistrer_prêt`,
      {
        p_montant_prêt: parseFloat(amount),
        p_numéro_prêt: numeroPret,
        p_montant_jeton: parseFloat(tokenAmount),
        p_remise_jeton: giveTokenType,
        p_moyen_versement: giveType,
        p_date_signature: signatureDate,
        p_date_clôture: clotureDate,
        p_numéro_compte: numeroCompte,
        p_code_monnaie: monnaie,
      },
      { headers: adminHeaders(true) }
    )
  ).data
}

export const smsValidation = async (code) => {
  return (
    await axios.post(
      `${baseUrl}/rpc/valider_sms`,
      { p_num_validation: code },
      { headers: adhHeaders(true) }
    )
  ).data
}

export const getAccountOwner = (accountId) =>
  cache(cacheObj, 'account_' + accountId, async () => {
    return (
      await axios.post(
        `${baseUrl}/rpc/propriétaire_compte`,
        { numéro_compte_cible: accountId },
        { headers: adhHeaders(true) }
      )
    ).data
  })

export const supprimerCompte = async (p_id_personne) => {
  return (
    await axios.post(
      `${baseUrl}/rpc/supprimer_personne`,
      { p_id_personne },
      { headers: adminHeaders(true) }
    )
  ).data
}

let cacheIndObj = {}

export const clearIndCache = () => {
  cacheIndObj = {}
}

export const getIndicatifs = () =>
  cache(cacheIndObj, 'ind', async () => {
    return (await axios.get(`${baseUrl}/v_indicatifs`, { headers: adhHeaders() })).data
  })

export const initTel = async (p_code_indicatif, p_num_tel) => {
  const response = await axios.post(
    `${baseUrl}/rpc/initel`,
    { p_code_indicatif, p_num_tel },
    { headers: adhHeaders(true) }
  )
  if (response.data[0].code === 'SMS Envoyé') {
    return 'SMS'
  }
  return response.data
}

export const updateAddress = async ({ ligne1, cp, ville }) => {
  const response = await axios.post(
    `${baseUrl}/rpc/majadresse`,
    { ligne1, cp, ville },
    { headers: adhHeaders(true) }
  )
  clearUserCache()
  return response.data
}

export const getAccountWithId = async (numéro_compte) => {
  const response = await axios.get(`${baseUrl}/v_lcomptes?numéro_compte=eq.${numéro_compte}`, {
    headers: adminHeaders(),
  })
  return response.data?.[0]
}
