import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../utils/providers/AuthContext'
import '../../style/layout/Footer.scss'

export default function Footer() {
  const { refreshSession } = useContext(AuthContext)

  return (
    <div>
      <footer onMouseMove={refreshSession}>
        <div className="grid-column">
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <img
              src={process.env.REACT_APP_PUBLIC_URL + '/images/acronyms/2_sigle_FL_blanc.png'}
              width="60px"
              alt="logo, franc libre, monnaie"
            />
            <img
              src={process.env.REACT_APP_PUBLIC_URL + '/images/titles/LOGO_LFL_Blanc.png'}
              className="title-img"
              width="90px"
              alt="titre, la financière libre"
            />
          </div>
        </div>
        <div className="grid-column">
          <div>
            <Link to={'/mentions-legales'}>Mentions légales</Link>
          </div>
        </div>
        <div className="grid-column">
          {/*<div className="icon-link">
            <a
              className="icon-fl"
              href="https://www.le-franc-libre.fr"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={process.env.REACT_APP_PUBLIC_URL + '/images/titles/logo-le-franc-libre.png'}
                width="160px"
                alt=""
              />
            </a>
          </div>
          <div className="icon-link">
            <a href="https://comitedesalutpublic.fr" rel="noreferrer" target="_blank">
              <img
                src={process.env.REACT_APP_PUBLIC_URL + '/images/elements/comite-salut-public.png'}
                width="60px"
                alt="comite salut public, souverainete"
              />
            </a>
          </div>*/}
        </div>
      </footer>
    </div>
  )
}
