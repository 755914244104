import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import {
  AuthContext,
  getTokenIsAdmin,
  getTokenIsLocalAdmin,
} from '../../utils/providers/AuthContext'
import { shuffleNumbers } from '../../utils/functions/shuffleNumbers'
import { loginUser } from '../../data/services/apiUser'
import RenderFormConnection from '../../utils/renders/RenderFormConnection'
import isNumeric from 'validator/es/lib/isNumeric'
import HelpContainer from '../UI/HelpContainer'
import '../../style/screens/Connection.scss'

const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j']

export default function Connection() {
  const [digits] = useState(shuffleNumbers([...Array(10).keys()]))
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [totp, setTotp] = useState('')
  const [errorLogin, setErrorLogin] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const { setIsConnected, setIsAdmin } = useContext(AuthContext)
  const [errorMsg, setErrorMsg] = useState('')
  const { pageLoading } = usePageAuth(true)
  let navigate = useNavigate()

  function handleClickDigit(e) {
    e.preventDefault()
    setPassword((password + e.target.name).substring(0, 8))
  }

  function handleClearPassword(e) {
    e.preventDefault()
    setPassword('')
  }

  function handleChangeLogin(e) {
    e.preventDefault()
    setLogin(e.target.value)
  }

  function handleChangePassword(e) {
    e.preventDefault()
    setPassword(e.target.value)
  }

  function handleChangeTotp(e) {
    e.preventDefault()
    setTotp(e.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    // Réinitialisation des champs d'erreur
    setErrorLogin('')
    setErrorPassword('')
    let success = true
    /*if (login.length !== 8) {
      success = false
      setErrorLogin("Le nombre de chiffres pour l'identifiant est insuffisant")
    }*/
    /*if (password.length !== 8) {
      success = false
      setErrorPassword('Le nombre de chiffres pour le mot de passe est insuffisant')
    }*/
    if (success === false) {
      return
    }

    /*const decrypt = password
      .split('')
      .map((char) => digits[letters.indexOf(char)])
      .join('')*/

    try {
      const token = await loginUser(login, password, totp)
      localStorage.setItem('accessToken', token.access_token)
      localStorage.setItem('accessTokenExpires', Date.now() + token.expires_in * 1000)
      localStorage.setItem('refreshToken', token.refresh_token)
      localStorage.setItem('refreshTokenExpires', Date.now() + token.refresh_expires_in * 1000)
      setIsConnected(true)
      // startSession()
      const checkAdmin = getTokenIsAdmin()
      setIsAdmin(checkAdmin)
      /*const checkLocalAdmin = getTokenIsLocalAdmin()
      setIsAdmin(checkLocalAdmin)*/

      // On redirige en fonction du rang
      navigate('/compte')
    } catch (error) {
      if (
        error.response.status === 401 &&
        error.response.data?.error_description === 'Invalid user credentials'
      ) {
        setErrorMsg('Identifiant ou mot de passe invalide')
      } else {
        setErrorMsg(error.message)
      }
    }
  }

  return pageLoading ? (
    <main className="page-msg">
      <p>Chargement..</p>
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button className="back-button-edit" onClick={() => setErrorMsg('')}>
        Retour
      </button>
    </main>
  ) : (
    <>
      <div className="fresco-container">
        <img
          src={process.env.REACT_APP_PUBLIC_URL + '/images/elements/FRESQUE_BD_LG.jpg'}
          width="100%"
          alt="fesque,franc libre argent dématérialisé, banque en ligne, retrait, transfert"
        />
      </div>
      <div className="fresco-texts">
        <img
          style={{ maxWidth: '1000px' }}
          src={process.env.REACT_APP_PUBLIC_URL + '/images/titles/Logo_LFL_Long.png'}
          alt="banque france libre, résilience, résistance"
        />
      </div>

      <main className="login-page">
        <div className="texts-blocs">
          <div className="gap">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_cadenas_ferm.png'}
              height="40px"
              width="40px"
              className="space-icon"
              alt="connexion du compte, securisation"
            />
            <p className="space-text less-spacement">MON ESPACE</p>
            <RenderFormConnection
              handleSubmit={handleSubmit}
              handleChangeLogin={handleChangeLogin}
              login={login}
              errorLogin={errorLogin}
              errorPassword={errorPassword}
              password={password}
              handleChangePassword={handleChangePassword}
              totp={totp}
              handleChangeTotp={handleChangeTotp}
            />
          </div>
          <HelpContainer
            links={[
              /*{
                img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_monnaie.png',
                text: 'Monnaie',
                path: '/monnaie',
              },*/
              {
                img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_hotline.png',
                text: "Besoin d'aide ? Contactez-nous",
                path: '/contact',
              },
            ]}
          />
        </div>
      </main>
    </>
  )
}
