import '../../style/screens/UserEdit.scss'
import { useEffect, useState } from 'react'
import { CgClose } from 'react-icons/cg'
import LoadingSpinner from '../../component/UI/LoadingSpinner'
import { getIndicatifs } from '../../data/services/apiUser'

export const RenderFormUpdateAddress = ({ onSubmit, close, error }) => {
  return (
    <div style={{ margin: '10px 0' }}>
      <div
        style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Mettre à jour mon adresse
        <CgClose color={'red'} fontSize={'18px'} onClick={close} style={{ cursor: 'pointer' }} />
      </div>
      <form onSubmit={onSubmit}>
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <input name={'ligne1'} placeholder={'Ligne 1'} />
          <input name={'ville'} placeholder={'Ville'} />
          <input name={'cp'} placeholder={'Code postal'} />
        </div>
        {error && (
          <div
            style={{ color: '#cd171a', textAlign: 'center', fontSize: '13px', margin: '10px 0' }}
          >
            {error}
          </div>
        )}
        <button className={'profitable-btn'} type={'submit'}>
          Valider
        </button>
      </form>
    </div>
  )
}
