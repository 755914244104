import axios from 'axios'
import { baseUrl } from '../constants/constants'
import { adhHeaders, adminHeaders, cache } from './api'

let cacheObj = {}
let responsabilitesCacheObj = {}

export const clearCaisseCache = () => {
  cacheObj = {}
  responsabilitesCacheObj = {}
}

export const getVerrousCaisseWithId = (code) =>
  cache(cacheObj, 'caisse_verrous_' + code, async () => {
    return axios.get(baseUrl + `/v_lverrous_locaux?code_caisse=eq.${code}`, {
      headers: adminHeaders(),
    })
  })

export const getCaisse = () =>
  cache(cacheObj, 'caisse', async () => {
    return Promise.all([
      axios.get(baseUrl + '/v_lverrous_locaux', { headers: adhHeaders() }),
      axios.get(baseUrl + '/v_lopérations_caisse', { headers: adhHeaders() }),
      axios.get(baseUrl + '/v_soldes_caisse', { headers: adhHeaders() }),
      axios.get(baseUrl + '/v_verrou_local', { headers: adhHeaders() }),
    ])
  })

export const clearCaisseWithIdCache = (code) => {
  delete cacheObj['caisse_' + code]
  console.log(cacheObj)
}
export const getCaisseWithId = (code) =>
  cache(cacheObj, 'caisse_' + code, async () => {
    return Promise.all([
      axios.get(baseUrl + '/v_lverrous_locaux?code_caisse=eq.' + code, { headers: adminHeaders() }),
      axios.get(baseUrl + '/v_lopérations_caisse?code_caisse=eq.' + code, {
        headers: adminHeaders(),
      }),
      axios.get(baseUrl + '/v_soldes_caisse?code_caisse=eq.' + code, { headers: adminHeaders() }),
    ])
  })

export const getResponsabilites = () =>
  cache(responsabilitesCacheObj, 'responsabilites', async () => {
    return (await axios.get(baseUrl + '/v_responsabilités', { headers: adminHeaders() })).data
  })

export const getCaisseList = () =>
  cache(
    cacheObj,
    'caisses',
    async () => (await axios.get(baseUrl + '/v_lcaisses', { headers: adminHeaders() })).data
  )

export const deverroullerCaisse = async () => {
  const response = await axios.post(
    `${baseUrl}/rpc/déverrouiller_caisse`,
    {},
    {
      headers: adhHeaders(true),
    }
  )
  clearCaisseCache()
  if (response.data?.[0]?.code === 'SMS Envoyé') {
    return 'SMS'
  }
  return response.data
}

export const enregistrerAchat = async (data) => {
  return (
    await axios.post(`${baseUrl}/rpc/enregistrer_achat_fl`, data, { headers: adhHeaders(true) })
  ).data
}

export const enregistrerDepot = async (data) => {
  return (
    await axios.post(`${baseUrl}/rpc/enregistrer_dépôt_fl`, data, { headers: adhHeaders(true) })
  ).data
}

export const enregistrerOperateur = async (data) => {
  return (
    await axios.post(`${baseUrl}/rpc/enregistrer_opérateur_caisse`, data, {
      headers: adminHeaders(true),
    })
  ).data
}

export const enregistrerOperateurPersonne = async (data) => {
  return (
    await axios.post(`${baseUrl}/rpc/enregistrer_opérateur_caisse_id`, data, {
      headers: adminHeaders(true),
    })
  ).data
}
