import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  baseUrl,
  currencyText,
  keycloakRealm,
  keycloakUrl,
  monhost,
  stripePublishableKey,
} from '../../data/constants/constants'
import { adhHeaders } from '../../data/services/api'
import { exchangeRate, initAchat } from '../../data/services/apiTransaction'
import {
  clearUserCache,
  deleteUserKeycloakCredentials,
  getUserDataSelf,
  initTel,
  updateAddress as updateAddressApi,
} from '../../data/services/apiUser'
import '../../style/screens/Account.scss'
import { forceInputNumber } from '../../utils/functions/forceInput'
import { formatDate, timestampToDate } from '../../utils/functions/timeDate'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { AuthContext, getTokenIsLocalAdmin } from '../../utils/providers/AuthContext'
import { useSMSVerificationContext } from '../../utils/providers/SMSVerificationContext'
import { RenderFormInitPhone } from '../../utils/renders/RenderFormInitPhone'
import { RenderFormUpdateAddress } from '../../utils/renders/RenderFormUpdateAddress'
import { Accounts } from '../account/Accounts'
import HelpContainer from '../UI/HelpContainer'
import LoadingSpinner from '../UI/LoadingSpinner'

export const StripeForm = () => {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_PUBLIC_URL + '/compte?payment=complete',
      },
    })

    if (error) {
      setErrorMessage(error.message)
    } else {
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe} className="transa-btn">
        Valider
      </button>
      {/* Show error message to your customers */}
      {errorMessage && <p className={'text-align-ce'}>{errorMessage}</p>}
    </form>
  )
}

export default function Account() {
  const [errorMsg, setErrorMsg] = useState('')
  const [reject, setReject] = useState(false)
  const { logOutErrorHandler } = useContext(AuthContext)

  const [user, setUser] = useState(null)
  const { pageLoading, setPageLoading } = usePageAuth()
  const navigate = useNavigate()
  // Message copy
  const [msgCopySuccess, setMsgCopy] = useState('')
  const [copySuccess, setCopySuccess] = useState(false)
  const textAreaRef = useRef(null)

  const [initPhone, setInitPhone] = useState(false)
  const [updateAddress, setUpdateAddress] = useState(false)
  const [updateAddressError, setUpdateAddressError] = useState(null)

  const [openStripe, setOpenStripe] = useState(false)

  const [inputErrors, setInputErrors] = useState({})

  const [amount, setAmount] = useState(0)
  const [rate, setRate] = useState(1)
  const [stripeClientSecret, setStripeClientSecret] = useState(null)
  const location = useLocation()
  const [stripePromise, setStripePromise] = useState()

  const [searchParams, setSearchParams] = useSearchParams()
  const paymentComplete = searchParams.get('payment') === 'complete'
  const paymentFinalized = searchParams.get('paymentFinalized') === 'true'
  const paymentIntentId = searchParams.get('payment_intent')

  const { RenderSMSVerification, inVerification, setInVerification } = useSMSVerificationContext()

  async function fetchUser() {
    setPageLoading(true)
    try {
      // result.ss = null // TEST TEMPORARY // setExchangeRate(exange)
      const result = await getUserDataSelf()
      setRate(await exchangeRate())
      setUser(result)
    } catch (error) {
      if (logOutErrorHandler(error)) {
        return
      }
      setErrorMsg(error.message)
    }
    setPageLoading(false)
  } // Récupération des données user

  useEffect(() => {
    if (getTokenIsLocalAdmin()) {
      setErrorMsg("Vous n'êtes pas autorisé")
      setReject(true)
    } else {
      fetchUser()
    }
  }, [])

  const handleChangeAmount = (e) => setAmount(forceInputNumber(amount, e.target.value, 7))

  function copyToClipboard(e) {
    textAreaRef.current.select() // contenteditable
    document.execCommand('copy')
    e.target.focus()
    setCopySuccess(true)
    setMsgCopy('COPIÉ !!!')
    setTimeout(() => {
      setCopySuccess(false)
    }, 900)
  }

  function handleListener() {
    console.log('Copy')
  }

  const onInitPhoneSubmitSuccess = () => {
    clearUserCache()
    setInitPhone(false)
    fetchUser()
  }

  const initPhoneSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const { ind, phone } = Object.fromEntries(formData)
    try {
      const response = await initTel(ind, phone)
      if (response === 'SMS') {
        setInVerification(true)
        return
      }
      onInitPhoneSubmitSuccess()
    } catch (e) {
      setErrorMsg("Une erreur est survenue pendant l'initialisation du téléphone")
    }
  }

  const updateAddressSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const { ville, cp, ligne1 } = Object.fromEntries(formData)
    if (isNaN(parseInt(cp)) || cp.length !== 5) {
      setUpdateAddressError('Mauvais code postal')
      return
    }
    try {
      const response = await updateAddressApi({ ville, cp, ligne1 })
      setUpdateAddress(false)
      setUpdateAddressError(null)
      fetchUser()
    } catch (e) {
      setUpdateAddressError('Une erreur est survenue')
    }
  }

  useEffect(() => {
    if (paymentComplete && paymentIntentId && !paymentFinalized) {
      searchParams.set('paymentFinalized', 'true')
      setSearchParams(searchParams)
      axios
        .post(
          `${baseUrl}/rpc/finaliser_achat_fl`,
          { p_id_paiement: paymentIntentId },
          { headers: adhHeaders(true) }
        )
        .then(() => {})
        .catch(() => {})
    }
  }, [paymentComplete, paymentIntentId, paymentFinalized])

  useEffect(() => {
    if (openStripe && !stripePromise) {
      setStripePromise(loadStripe(stripePublishableKey))
    }
  }, [openStripe])

  function canSubmitStripe() {
    const errors = {
      account: '',
      name: '',
      amount: '',
    }
    if (amount <= 0) {
      errors.amount = 'Le montant doit être supérieur à zéro'
    }
    setInputErrors(errors)
    let success = Object.values(errors).every((el) => el.length === 0) // passe toutes les valeurs en vérifiant que ce sont des bien chaînes de caractère vides (bouléen vrai)
    return success
  }

  async function handleStripePayment(e) {
    e.preventDefault()
    if (!canSubmitStripe()) return
    try {
      const result = await initAchat(amount)
      const clientSecret = result?.[0]?.client_secret
      setStripeClientSecret(clientSecret)
    } catch (error) {}
  }

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button
        className="back-button-edit"
        onClick={() => {
          if (reject) {
            navigate('/compte')
          } else {
            setErrorMsg('')
          }
        }}
      >
        Retour
      </button>
    </main>
  ) : inVerification ? (
    <main className="page-msg">
      <RenderSMSVerification onSuccess={onInitPhoneSubmitSuccess} />
    </main>
  ) : (
    <>
      <HelpContainer
        links={[
          {
            path: '/operation',
            img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_curseurs.png',
            text: 'Mes opérations',
          },
          {
            path: '/beneficiaires',
            img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_compte.png',
            text: 'Mes bénéficiaires',
          },
        ]}
      />
      <main className="personnal-space">
        <div className="texts-blocs">
          <h2>MON COMPTE</h2>
          <div className={'actions'}>
            <button
              onClick={() => {
                if (stripeClientSecret) {
                  setStripeClientSecret(undefined)
                }
                setOpenStripe(!openStripe)
              }}
              className="stripe-btn"
              style={{ marginLeft: '0', marginRight: '0' }}
            >
              {openStripe ? (
                <span>Fermer la fenêtre de paiement</span>
              ) : (
                <span>Recharger mon compte par CB</span>
              )}
            </button>
          </div>
          {openStripe &&
            (stripeClientSecret ? (
              <>
                <Elements stripe={stripePromise} options={{ clientSecret: stripeClientSecret }}>
                  <div className={'transa-form'}>
                    <StripeForm />
                  </div>
                </Elements>
              </>
            ) : (
              <form className="transa-form">
                <label>Montant de {currencyText} à acheter</label>
                <input
                  value={amount}
                  onChange={handleChangeAmount}
                  type={'number'}
                  required={true}
                />
                {inputErrors.amount && <p className=" text-align-ce">{inputErrors.amount}</p>}
                <p className={'text-align-c'} style={{ fontStyle: 'italic', fontSize: 13 }}>
                  Montant en euros : {amount * rate}€
                </p>

                <button onClick={handleStripePayment} className="transa-btn">
                  Validation
                </button>
              </form>
            ))}
          {paymentComplete && (
            <p className="text-align-c green">
              Votre paiement a bien été pris en compte. Veuillez consulter votre boîte courriel (ou
              indésirable) pour récupérer votre justificatif de paiement.
            </p>
          )}

          <Accounts user={user} />
          <div className="saving-account">
            <h3 className="h-subtitle">Mes coordonnées :</h3>
            <div className="flex-user">
              {user.numeroCompte && (
                <div className="flex-id">
                  <p className="text-styled">Votre numéro de compte</p>
                  <button className="account-btn" onClick={copyToClipboard}>
                    {copySuccess ? msgCopySuccess : <span>COPIER</span>}
                  </button>
                </div>
              )}
              {user.birthday && (
                <p className="text-styled">
                  Date d'anniversaire : {formatDate(timestampToDate(user.birthday), 'D/M/YY')}
                </p>
              )}
              {user.ss?.length > 0 && (
                <p className="text-styled">N° de sécurité sociale : {user.ss}</p>
              )}
              {user.phoneNumber?.length > 0 && !initPhone ? (
                <>
                  <p className="text-styled">
                    N° de téléphone : {user.indicatif ?? ''}
                    {user.phoneNumber}
                  </p>
                  <button className={'profitable-btn'} onClick={() => setInitPhone(true)}>
                    Modifier nom numéro de téléphone
                  </button>
                </>
              ) : initPhone ? (
                <RenderFormInitPhone
                  update={user.phoneNumber?.length > 0}
                  onSubmit={initPhoneSubmit}
                  close={() => setInitPhone(false)}
                />
              ) : (
                <button className={'profitable-btn'} onClick={() => setInitPhone(true)}>
                  Initialiser nom numéro de téléphone
                </button>
              )}
              <p className="text-styled">Nombre de bénéficiaires : {user.contacts?.length ?? 0}</p>
              {user.adresse && (
                <p className="text-styled">
                  Adresse :{' '}
                  {user.adresse.ligne1 + ', ' + user.adresse.cp + ' ' + user.adresse.ville}
                </p>
              )}

              {updateAddress ? (
                <RenderFormUpdateAddress
                  error={updateAddressError}
                  onSubmit={updateAddressSubmit}
                  close={() => setUpdateAddress(false)}
                />
              ) : (
                <button className={'profitable-btn'} onClick={() => setUpdateAddress(true)}>
                  Mettre à jour mon adresse
                </button>
              )}
              {/* {user.level?.length > 0 &&  <p className="text-styled">Rang : {user.level}</p>} */}
              {/* <div className={'qr-code'}>
                <QRCode
                  value={
                    process.env.REACT_APP_PUBLIC_URL +
                    '/ajout_beneficiaire?' +
                    new URLSearchParams({
                      compte: user.numeroCompte,
                      name: user.name + ' ' + user.surname,
                    })
                  }
                />
              </div>*/}
            </div>
          </div>
          <div className="saving-account">
            <h3 className="h-subtitle">Double authentification :</h3>
            <div className="flex-user">
              {user.otp ? (
                <>
                  La double authentification est activée sur l'appareil nommé {user.otp.userLabel}
                  <br />
                  <button
                    className={'profitable-btn'}
                    onClick={async () => {
                      setPageLoading(true)
                      await deleteUserKeycloakCredentials(user.otp.id)
                      clearUserCache()
                      fetchUser()
                    }}
                  >
                    Supprimer la configuration
                  </button>
                </>
              ) : (
                <button
                  className={'profitable-btn'}
                  onClick={() =>
                    window.open(
                      keycloakUrl +
                        '/realms/' +
                        keycloakRealm +
                        '/protocol/openid-connect/auth?client_id=franclibre&kc_action=CONFIGURE_TOTP&response_type=code&scope=openid&redirect_uri=' +
                        monhost
                    )
                  }
                >
                  Configurer un appareil
                </button>
              )}
            </div>
          </div>
        </div>
        <textarea
          className="textarea-none"
          ref={textAreaRef}
          value={user.numeroCompte}
          onChange={handleListener}
        />
      </main>
      <HelpContainer
        links={[
          /*{
            path: '/enseignes',
            img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_in_situ.png',
            text: 'Où utiliser les francs libres ?',
          },*/
          {
            path: '/contact',
            img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_hotline.png',
            text: "Besoin d'aide ? Contactez-nous",
          },
        ]}
      />
    </>
  )
}
