import { FaTimesCircle } from 'react-icons/fa'
import { registerUrl } from '../../data/constants/constants'
import { mdpoublieUrl } from '../../data/constants/constants'

export default function RenderFormConnection({
  handleSubmit,
  handleChangeLogin,
  login,
  errorLogin,
  errorPassword,
  password,
  handleChangePassword,
  totp,
  handleChangeTotp,
}) {
  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div className="input-container">
        <div className="input-container">
          <label className="bolded less-spacement">IDENTIFIANT</label>
          <p className="login-text-mobile">Saisissez votre identifiant</p>
        </div>
        <input
          className="input-login"
          type="email"
          value={login}
          onChange={handleChangeLogin}
          name="uname"
          required
        />
        <div className="display-msg">{errorLogin}</div>
      </div>

      <div className="input-container">
        <div className="input-container">
          <label className="bolded less-spacement">MOT DE PASSE</label>
          <p className="login-text-mobile">Saisissez votre mot de passe</p>
        </div>
        <input
          className="input-login"
          type="password"
          value={password}
          onChange={handleChangePassword}
          name="password"
          required
        />
        <div className="display-msg">{errorPassword}</div>
      </div>
      <div className="input-container">
        <div className="input-container">
          <label className="bolded less-spacement">CODE UNIQUE</label>
          <label style={{ margin: 0, padding: 0, fontSize: 13, fontStyle: 'italic' }}>
            (Si usage téléphone)
          </label>
        </div>
        <input className="input-login" value={totp} onChange={handleChangeTotp} name="totp" />
      </div>
      <button className="button-submit">VALIDER</button>
      <a href={registerUrl}>S'inscrire</a>
      <a href={mdpoublieUrl}>Mot de passe oublié</a>
    </form>
  )
}
