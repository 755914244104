import { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../utils/providers/AuthContext'
import { RenderMenuHeader } from '../../utils/renders/RenderMenuHeader'
import { GiHamburgerMenu } from 'react-icons/gi'
import UserBanner from '../UI/UserBanner'
import LogOutPopUp from '../UI/PopUpLogOut'
import BurgerMenu, { useBurgerMenu } from '../UI/BurgerMenu'
import '../../style/layout/Header.scss'

export default function Header() {
  const { isConnected, refreshSession } = useContext(AuthContext)
  const { isOpen, toggle } = useBurgerMenu()
  const [isPopUpVisible, setIsPopUpVisible] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isConnected) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    setIsPopUpVisible(false)
  }, [location.pathname])

  function handleOpenPopUp(e) {
    e.preventDefault()
    setIsPopUpVisible(true)
  }

  function handleClosePopUp(e) {
    e.preventDefault()
    setIsPopUpVisible(false)
  }

  return (
    <>
      <header onMouseMove={refreshSession}>
        <nav>
          <ul className="list">
            <div className="title-page">
              <Link
                to={isConnected ? '/compte' : '/'}
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + '/images/acronyms/2_sigle_FL_blanc.png'}
                  width="60px"
                  alt="logo, franc libre, monnaie"
                />
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + '/images/titles/LOGO_LFL_Blanc.png'}
                  className="title-img"
                  width="90px"
                  alt="titre, la financière libre"
                />
              </Link>
            </div>
            <div className="list-navigation-h">
              <RenderMenuHeader onClickLogOut={handleOpenPopUp} />
            </div>
            <div>
              <GiHamburgerMenu className="burger-button" height="60px" onClick={toggle} />
            </div>
          </ul>
        </nav>
        <div className="burger-menu-container">
          <BurgerMenu isOpen={isOpen}>
            <RenderMenuHeader onClickLogOut={handleOpenPopUp} />
          </BurgerMenu>
        </div>
        {isPopUpVisible && <LogOutPopUp onClose={handleClosePopUp} />}
      </header>
      {isConnected && <UserBanner />}
    </>
  )
}
