import { useEffect, useState } from 'react'
import { getResponsabilites } from '../../data/services/apiCaisse'
import {
  forceInputAlpha,
  forceInputEmail,
  forceInputNumber,
} from '../../utils/functions/forceInput'
import '../../style/UI/UserSearch.scss'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export default function UserSearch({ onSubmit, onReset }) {
  const location = useLocation()
  const params = queryString.parse(location.search)
  // children
  const [searchCategory, setSearchCategory] = useState(params.searchCategory ?? 'NAME')
  const [searchName, setSearchName] = useState(
    params.searchCategory === 'NAME' ? params.searchValue ?? '' : ''
  )
  const [searchEmail, setSearchEmail] = useState(
    params.searchCategory === 'EMAIL' ? params.searchValue ?? '' : ''
  )
  const [searchPhone, setSearchPhone] = useState(
    params.searchCategory === 'PHONE' ? params.searchValue ?? '' : ''
  )
  const [searchSS, setSearchSS] = useState(
    params.searchCategory === 'SS' ? params.searchValue ?? '' : ''
  )
  const [searchRole, setSearchRole] = useState(
    params.searchCategory === 'RANG' ? params.searchValue ?? '' : ''
  )
  const [searchAccountNumber, setSearchAccountNumber] = useState(
    params.searchCategory === 'NUMEROCOMPTE' ? params.searchValue ?? '' : ''
  )
  const [searchValue, setSearchValue] = useState('')

  const [responsabilites, setResponsabilites] = useState([])
  // console.log('a', searchValue)
  // console.log('b', params.searchValue)

  // Rediriger en fonction de la valeur de recherche
  useEffect(() => {
    const list = {
      NAME: searchName,
      EMAIL: searchEmail,
      PHONE: searchPhone,
      SS: searchSS,
      RANG: searchRole,
      NUMEROCOMPTE: searchAccountNumber,
    }
    setSearchValue(list[searchCategory] ?? '')
    // console.log(list[searchCategory])
  }, [
    searchCategory,
    searchName,
    searchSS,
    searchEmail,
    searchPhone,
    searchRole,
    searchAccountNumber,
  ])

  useEffect(() => {
    getResponsabilites().then((r) => {
      setResponsabilites(r)
      if (r.length > 0 && searchRole === '') {
        setSearchRole(r[0]['nom_responsabilité'])
      }
    })
  }, [])

  const handleChangeCategory = (e) => setSearchCategory(e.target.value)

  // Input
  function handleChangeSearch(e) {
    e.preventDefault()
    if (searchCategory === 'NAME') {
      setSearchName(forceInputAlpha(searchName, e.target.value))
    }
    if (searchCategory === 'EMAIL') {
      setSearchEmail(forceInputEmail(searchEmail, e.target.value))
    }
    if (searchCategory === 'PHONE') {
      setSearchPhone(forceInputNumber(searchPhone, e.target.value, 10))
    }
    if (searchCategory === 'SS') {
      setSearchSS(forceInputNumber(searchSS, e.target.value, 13))
    }
    if (searchCategory === 'RANG') {
      setSearchRole(e.target.value)
    }
    if (searchCategory === 'NUMEROCOMPTE') {
      setSearchAccountNumber(forceInputNumber(searchAccountNumber, e.target.value))
    }
  }

  // Buttons
  function handleSubmit(e) {
    e.preventDefault()
    if (searchValue.length > 0) {
      onSubmit({ searchCategory, searchValue }) // new canSearch
    }
  }

  function handleClear(e) {
    e.preventDefault()
    if (params?.searchValue?.length > 0) {
      onReset()
    }
    // }
  }

  return (
    <div className="search-container">
      <h4>Recherche :</h4>
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="options-writing">
          <select value={searchCategory} onChange={handleChangeCategory}>
            <option value="NAME">NOM DE FAMILLE</option>
            <option value="EMAIL">COURRIEL</option>
            <option value="PHONE">N° DE TELEPHONE</option>
            <option value="SS">N° DE SECU</option>
            <option value="RANG">RANG</option>
            <option value="NUMEROCOMPTE">N° DE COMPTE</option>
          </select>
          {searchCategory === 'NAME' && (
            <input type="search" onChange={handleChangeSearch} value={searchName} />
          )}
          {searchCategory === 'EMAIL' && (
            <input type="search" onChange={handleChangeSearch} value={searchEmail} />
          )}
          {searchCategory === 'PHONE' && (
            <input type="search" onChange={handleChangeSearch} value={searchPhone} />
          )}
          {searchCategory === 'SS' && (
            <input type="search" onChange={handleChangeSearch} value={searchSS} />
          )}
          {searchCategory === 'RANG' && (
            <select value={searchRole} onChange={handleChangeSearch}>
              {responsabilites.map(({ nom_responsabilité }) => (
                <option value={nom_responsabilité.toUpperCase()}>
                  {nom_responsabilité.toUpperCase()}
                </option>
              ))}
              <option value={'NOUVEAU'}>NOUVEAU</option>
              <option value={'CANDIDAT'}>CANDIDAT</option>
            </select>
          )}
          {searchCategory === 'NUMEROCOMPTE' && (
            <input type="search" onChange={handleChangeSearch} value={searchAccountNumber} />
          )}
        </div>
        <div className="action">
          <button
            className="action-button"
            onClick={handleSubmit}
            disabled={searchValue.length === 0}
          >
            Rechercher
          </button>
          <button
            className="action-button reset-button"
            onClick={handleClear}
            disabled={params.searchValue ? params.searchValue.length === 0 : true}
          >
            Réinitialiser
          </button>
        </div>
      </form>
    </div>
  )
}
