import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getUser, getUserDataSelf } from '../../data/services/apiUser'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { getTokenIsLocalAdmin } from '../../utils/providers/AuthContext'
import { Accounts } from '../account/Accounts'
import '../../style/screens/Account.scss'
import HelpContainer from '../UI/HelpContainer'
import LoadingSpinner from '../UI/LoadingSpinner'

export const UserAccount = () => {
  const { id } = useParams()
  const location = useLocation()
  const {} = queryString.parse(location.search)
  const { pageLoading, setPageLoading } = usePageAuth()

  const [reject, setReject] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')

  const [user, setUser] = useState(null)

  console.log(user)

  const navigate = useNavigate()

  useEffect(() => {
    async function fetchUser() {
      setPageLoading(true)
      try {
        // result.ss = null // TEST TEMPORARY // setExchangeRate(exange)
        const result = await getUser(id)
        setUser(result)
      } catch (error) {
        setErrorMsg(error.message)
      }
      setPageLoading(false)
    } // Récupération des données user

    if (getTokenIsLocalAdmin()) {
      setErrorMsg("Vous n'êtes pas autorisé")
      setReject(true)
    } else {
      fetchUser()
    }
  }, [])

  if (pageLoading) {
    return (
      <main className="page-msg">
        <LoadingSpinner />
      </main>
    )
  }

  if (errorMsg) {
    return (
      <main className="page-msg">
        <p className="text-align-c">{errorMsg}</p>
        <button
          className="back-button-edit"
          onClick={() => {
            if (reject) {
              navigate('/compte')
            } else {
              setErrorMsg('')
            }
          }}
        >
          Retour
        </button>
      </main>
    )
  }

  return (
    <div>
      <h2>COMPTE {user.accountNumber}</h2>
      <HelpContainer
        links={[
          {
            path: `/utilisateur/${id}/operation`,
            img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_synthese.png',
            text: 'Operations',
          },
          {
            path: `/utilisateur/edit/${user.id}?${new URLSearchParams({
              firstname: user.name,
              lastname: user.surname,
              ss: user.ss,
              phone: user.phoneNumber,
              indicatif: user['indicatif']?.replace('+', ''),
              numeroCompte: user.numeroCompte,
              from: `/utilisateur/${user.id}`,
            }).toString()}`,
            img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_curseurs.png',
            text: 'Modifier',
          },
        ]}
      />
      <main className="personnal-space">
        <div className="texts-blocs">
          <Accounts user={user} />
        </div>
      </main>
    </div>
  )
}
