import '../../style/UI/PopUpLogOut.scss'
export const ConfirmPopUp = ({
  onClose,
  title,
  cancelButtonText = 'Annuler',
  confirmButtonText = 'Confirmer',
  onCancel,
  onConfirm,
}) => {
  return (
    <>
      <div className="popup-overlay" onClick={onClose}></div>
      <div className="popup-container">
        <p className="popup-container__msg">{title}</p>
        <div className="popup-container__button">
          <button onClick={onCancel}>{cancelButtonText}</button>
          <button onClick={onConfirm}>{confirmButtonText}</button>
        </div>
      </div>
    </>
  )
}
