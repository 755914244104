import { Document, Page, StyleSheet, View, Text, Image } from '@react-pdf/renderer'
import { amountSuffix } from '../../data/constants/constants'
import { formatDate } from '../functions/timeDate'
import { GlobalFooter } from './GlobalFooter'
import { StyledGenericTablePDF } from './StyledGenericTablePDF'

export const AccountOperationsPDF = ({ operations, userId, from, to, user, self }) => {
  const title =
    "RELEVE D'OPERATIONS du " +
    formatDate(from, 'D/M/YY') +
    ' au ' +
    formatDate(to, 'D/M/YY') +
    (!self ? ' de ' + user.name + ' ' + user.surname : '')
  return (
    <Document>
      <Page size={'A4'} orientation={'portrait'}>
        <View style={styles.header}>
          <View style={styles.imageContainer}>
            <Image
              src={process.env.REACT_APP_PUBLIC_URL + '/images/acronyms/1_sigle_FL_noir.png'}
              style={styles.sigle}
            />
            <Image
              src={process.env.REACT_APP_PUBLIC_URL + '/images/titles/LOGO_LFL_Noir.png'}
              style={styles.logo}
            />
          </View>
          <Text style={styles.headerText}>{title}</Text>
        </View>
        <StyledGenericTablePDF columns={getColumns(userId)} data={operations} />
        {operations.length === 0 && (
          <View style={styles.empty}>
            <Text style={styles.emptyText}>Aucune opération pendant cette période</Text>
          </View>
        )}
        <View style={styles.experimental}>
          <Text style={styles.experimentalText}>
            Ce document est émis à partir d'un site expérimental de développement informatique et ne
            constitue pas un document opposable juridiquement.
          </Text>
        </View>
        <GlobalFooter title={title} />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  imageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  sigle: {
    width: 30,
  },
  logo: {
    width: 45,
  },
  header: {
    padding: 25,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 9,
    fontFamily: 'Helvetica',
  },
  empty: {
    marginTop: 10,
    marginBottom: 20,
  },
  emptyText: {
    fontSize: 10,
    fontFamily: 'Helvetica',
    textAlign: 'center',
  },
  experimental: {
    marginTop: 10,
    paddingHorizontal: 30,
  },
  experimentalText: {
    fontSize: 8,
    fontFamily: 'Helvetica-Oblique',
  },
})

const getColumns = (userId) => [
  {
    Header: 'Date',
    accessor: (row) => formatDate(row.time, 'D/M/YY'),
  },
  {
    Header: 'Tiers',
    accessor: 'tier',
  },
  {
    Header: "Nature de l'opération",
    accessor: (row) =>
      String(row.nature + (row.id && row.id !== 'N/A' ? ' (' + row.id + ')' : '')).toUpperCase(),
  },
  {
    Header: 'Montant',
    accessor: (row) => row.amount + amountSuffix,
  },
]
