import { useState } from 'react'
import { BsChevronRight } from 'react-icons/bs'
import { RiLuggageDepositLine } from 'react-icons/ri'
import { MdOutlineCreate, MdOutlineDeleteOutline } from 'react-icons/md'
import { ImProfile } from 'react-icons/im'
import { Link } from 'react-router-dom'
import { formatDate, timestampToDate } from '../../utils/functions/timeDate'
import { getTokenIsAdmin, getTokenAccount } from '../providers/AuthContext'
import { jsPDF } from 'jspdf'

export default function RenderUser({ user, index, page, perPage }) {
  const [isAdmin] = useState(getTokenIsAdmin())

  function handleImpressionLocal(e) {
    e.preventDefault()
    try {
      const doc = new jsPDF()
      let x = 15
      let y = 20
      doc.setFontSize(14)
      doc.text(`Rapport pour ${user.name + ' ' + user.surname}`, x, y)
      y += 12

      const actionTexts = {
        create: 'Création de compte', //numéro de compte
        update: "Modification d'utilisateur", // id
        delete: "Suppression d'utilisateur", // id
      }
      doc.setFontSize(11, 5)
      let n = 0
      // console.log(user.adminOperations);
      if (user.adminOperations !== null) {
        for (const action of user.adminOperations) {
          // for (const action of [...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations]) {
          doc.text(
            `${formatDate(action.date, 'D/M/YY')} - ${actionTexts[action.action] ?? 'Action'}`,
            x,
            y
          )
          doc.text(
            `${['update', 'delete'].includes(action.action) ? 'ID: ' : 'N°: '}${action.target}`,
            190,
            y,
            { align: 'right' }
          )
          y += 8
          // Ajout de nouvelles pages
          n++
          if (n % 30 === 0) {
            doc.insertPage()
            y = 20
          }
        }
      } else {
        doc.text('Aucune opération recensée', x, y)
      }
      doc.save(`${user.name + '_' + user.surname}_${Date.now()}.pdf`) // save file
    } catch (error) {
      console.error(error.message)
    }
  }

  return (
    <>
      <tr key={user.id_personne}>
        <th>{index + 1 + (page - 1) * perPage}</th>
        <td>{user.id_compte}</td>
        <td>{user.nom?.toUpperCase()}</td>
        <td>{user['prénom']}</td>
        <td className="hide-responsive">{user.niveau}</td>
        <td className="hide-responsive">{user.courriel}</td>
        <td className="hide-responsive">{user.ville}</td>
        <td className="hide-responsive">{(user['indicatif'] ?? '') + (user['téléphone'] ?? '')}</td>
        <td className="hide-responsive">{user['numéro_sécu']}</td>
        <td className="hide-responsive">{user['solde_compte']}</td>
        <td className="flex-actions">
          {(user.niveau === 'candidat' || isAdmin) && (
            <>
              <Link to={`/utilisateur/${user.id_personne}`} className={'deposit-link'}>
                <button className="view-btn">
                  Accéder
                  <BsChevronRight />
                </button>
              </Link>
              <Link
                to={`/utilisateur/edit/${user.id_personne}?${new URLSearchParams({
                  firstname: user['prénom'],
                  lastname: user.nom,
                  ss: user['numéro_sécu'],
                  phone: user['téléphone'],
                  indicatif: user['indicatif']?.replace('+', ''),
                  numeroCompte: user['numéro_compte'],
                }).toString()}`}
              >
                <button className="change-btn">
                  Modifier
                  <MdOutlineCreate />
                </button>
              </Link>
              <Link
                to={`/depot/${user.id_personne}?email=${user['courriel']}`}
                className={'deposit-link'}
              >
                <button className="deposit-btn">
                  Dépôt
                  <RiLuggageDepositLine />
                </button>
              </Link>
            </>
          )}
        </td>
      </tr>
    </>
  )
}
