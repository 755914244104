import '../../style/screens/UserEdit.scss'
import { useEffect, useState } from 'react'
import { CgClose } from 'react-icons/cg'
import LoadingSpinner from '../../component/UI/LoadingSpinner'
import { getIndicatifs } from '../../data/services/apiUser'

export const RenderFormInitPhone = ({ onSubmit, close, update }) => {
  const [indicatifs, setIndicatifs] = useState(null)

  useEffect(() => {
    const fetch = async () => {
      const result = await getIndicatifs()
      setIndicatifs(result)
    }
    fetch()
  }, [])

  return (
    <div>
      <div style={{ paddingBottom: '5px', display: 'flex', gap: '5px', alignItems: 'center' }}>
        {update ? 'Modifier' : 'Initialiser'} mon numéro de téléphone
        <CgClose color={'red'} fontSize={'18px'} onClick={close} style={{ cursor: 'pointer' }} />
      </div>
      {indicatifs ? (
        <form onSubmit={onSubmit}>
          <div
            style={{ display: 'flex', gap: '5px', justifyContent: 'center', padding: '5px 0 10px' }}
          >
            <span>+</span>
            <select
              name={'ind'}
              required
              style={{ minWidth: '0', width: '60px', margin: '0' }}
              placeholder={'Ind.'}
            >
              {indicatifs.map((indicatif) => (
                <option value={indicatif.code}>{indicatif.libellé}</option>
              ))}
            </select>
            <input name={'phone'} style={{ margin: '0' }} placeholder={'Numéro de téléphone'} />
          </div>
          <button className={'profitable-btn'} type={'submit'}>
            Valider
          </button>
        </form>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingSpinner margin={'10px auto'} size={70} fontSize={'7px'} />
        </div>
      )}
    </div>
  )
}
