import { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { AuthContext, getTokenIsController } from '../providers/AuthContext'
import { getUserDataSelf } from '../../data/services/apiUser'
import { ranks } from '../../data/constants/constants'
import { menuItems } from '../../data/constants/navMenuItems'
import '../../style/layout/Header.scss'

export function RenderMenuHeader({ onClickLogOut }) {
  const { isConnected, isAdmin, setIsAdmin, isLocalAdmin, setIsLocalAdmin } =
    useContext(AuthContext)
  const location = useLocation()

  const [user, setUser] = useState()

  useEffect(() => {
    async function fetchUser() {
      try {
        const user = await getUserDataSelf()
        setUser(user)
        setIsAdmin(user.level === ranks.ADMIN)
        setIsLocalAdmin(user.level === ranks.LOCAL_ADMIN)
      } catch (error) {
        console.log(error.message)
      }
    } // Récupération des données user
    fetchUser()
  }, [location.pathname])

  function renderMenuItem(el, index) {
    return (
      <li key={index} className="items">
        <NavLink to={el.path} className="items-nav">
          {typeof el.img === 'string' ? <img src={el.img} height="25px" alt={el.name} /> : el.img}
          <p>{el.name}</p>
        </NavLink>
      </li>
    )
  }

  function getUserTypeNavLinks() {
    const {
      contact,
      currency,
      account,
      operation,
      beneficiaries,
      shop,
      userlist,
      prets,
      delegation,
    } = menuItems
    // menu admin
    if (isAdmin) return [account, operation, beneficiaries, shop, userlist, prets, delegation]
    // menu local admin
    if (isLocalAdmin) return [shop, userlist]
    // menu user
    if (isConnected) return [account, operation, beneficiaries, shop]
    // menu disconnected
    return [contact /*, currency*/]
  }

  const getNavLinks = () => {
    const navLinks = [...getUserTypeNavLinks()]
    const isController = getTokenIsController()
    if (['TRS', 'ELU', 'SEC'].includes(user?.caisse?.role)) {
      navLinks.push(menuItems.caisseLocale)
    }
    if (isController) {
      navLinks.push(menuItems.userlist)
    }
    return navLinks
  }

  return (
    <>
      {getNavLinks().map(renderMenuItem)}

      {isConnected && (
        <li>
          <button className="items-nav" onClick={onClickLogOut}>
            <img
              src={process.env.REACT_APP_PUBLIC_URL + '/images/icons/blanc_on_off.png'}
              height="25px"
              alt="Déconnexion"
            />
            <p className="item-nav-btn">Déconnexion</p>
          </button>
        </li>
      )}
    </>
  )
}
