import { baseUrl } from '../constants/constants'
import axios from 'axios'
import Transaction from '../models/Transaction.ts'
import { adhHeaders, adminHeaders, cache } from './api'
import { clearUserCache } from './apiUser'

let cacheObj = {}

export const clearTransactionsCache = () => {
  cacheObj = {}
}

export const updateTransactionAmount = (cacheKey, transactionId, amount) => {
  cacheObj[cacheKey] = cacheObj[cacheKey]?.map((transaction) => {
    if (transaction.id === transactionId) {
      return { ...transaction, amount }
    }
    return transaction
  })
}

export const getSelfUserTransactions = (minDate, maxDate) =>
  cache(cacheObj, 'operations' + minDate + maxDate, async () => {
    let params = ''
    if (minDate) {
      params = '?date=gte.' + minDate + (maxDate ? '&date=lte.' + maxDate : '')
    }
    try {
      const response = await axios.get(`${baseUrl}/v_operations${params}`, {
        headers: adhHeaders(),
      })
      return response.data.map(
        (transactionData) =>
          new Transaction({
            id: transactionData.num_transaction,
            id_opération: transactionData.id_opération,
            amount: transactionData.montant,
            time: transactionData.date,
            account: transactionData.id_compte,
            nature: transactionData.nature,
            tier: transactionData.emetteur,
          })
      )
    } catch (error) {
      throw error
    }
  }) // User and transaction

export const getUserTransactions = (id, minDate, maxDate) =>
  cache(cacheObj, 'operations' + id + minDate + maxDate, async () => {
    try {
      let params = `?id_personne=eq.${id}`
      if (minDate) {
        params += '&and=(date.gte.' + minDate + (maxDate ? ',date.lt.' + maxDate : '') + ')'
      }
      const response = await axios.get(`${baseUrl}/v_loperations${params}`, {
        headers: adminHeaders(),
      })
      return response.data.map(
        (transactionData) =>
          new Transaction({
            id: transactionData.num_transaction,
            id_opération: transactionData.id_opération,
            amount: transactionData.montant,
            time: transactionData.date,
            account: transactionData.id_compte,
            nature: transactionData.nature,
            tier: transactionData.emetteur,
          })
      )
    } catch (error) {
      throw error
    }
  }) // User and transaction

export const createTransaction = async (source, cible, montant, commentaire = '') => {
  try {
    const response = await axios.post(
      `${baseUrl}/rpc/créer_virement_utilisateur`,
      {
        id_compte_src: source,
        numéro_compte_cible: cible,
        p_montant: montant,
        p_commentaire: commentaire,
      },
      {
        headers: adhHeaders(true),
      }
    )
    if (response?.error) {
      console.log('Erreur envoyée par le serveur', response.error)
      throw new Error(response.error)
    }
    if (response.data[0].code === 'SMS Envoyé') {
      return 'SMS'
    }
    clearTransactionsCache()
    clearUserCache()
  } catch (error) {
    throw error
  }
} // POST

export const exchangeRate = () =>
  cache(cacheObj, 'rate', async () => {
    try {
      const response = await axios.get(`${baseUrl}/v_cotation_fl`, { headers: adhHeaders() })
      return response.data?.[0]?.taux
    } catch (error) {
      throw error
    }
  })

export const getActifs = () =>
  cache(cacheObj, 'actifs', async () => {
    try {
      const response = await axios.get(`${baseUrl}/v_uc_circulant`, { headers: adminHeaders() })
      return response.data?.[0]?.circulant
    } catch (error) {
      throw error
    }
  })

export const modifAchat = async (id_achat, nouveau_montant) => {
  const response = await axios.post(
    `${baseUrl}/rpc/modif_achat`,
    {
      p_id_achat: id_achat,
      p_nouvelle_quantité: nouveau_montant,
    },
    {
      headers: adminHeaders(true),
    }
  )
  return response.data
}

export const modifDepot = async (id_compte, date, nouveau_montant) => {
  const response = await axios.post(
    `${baseUrl}/rpc/modif_dépôt`,
    {
      p_id_compte: id_compte,
      p_date: date,
      p_nouveau_montant: nouveau_montant,
    },
    {
      headers: adminHeaders(true),
    }
  )
  return response.data
}

export const initAchat = async (p_montant) => {
  const response = await axios.post(
    `${baseUrl}/rpc/initier_achat_fl`,
    {
      p_montant,
    },
    {
      headers: adhHeaders(true),
    }
  )
  return response.data
}
