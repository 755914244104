import { useEffect, useState } from 'react'
import { getResponsabilites } from '../../data/services/apiCaisse'
import {
  forceInputAlpha,
  forceInputEmail,
  forceInputNumber,
} from '../../utils/functions/forceInput'
import '../../style/UI/UserSearch.scss'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const PretSearch = ({ onSubmit, onReset }) => {
  const location = useLocation()
  const params = queryString.parse(location.search)
  // children
  const [searchCategory, setSearchCategory] = useState(params.searchCategory ?? 'NUMERO')
  const [searchNumero, setSearchNumero] = useState(
    params.searchCategory === 'NUMERO' ? params.searchValue ?? '' : ''
  )
  const [searchNomPersonne, setSearchNomPersonne] = useState(
    params.searchCategory === 'NOM_PERSONNE' ? params.searchValue ?? '' : ''
  )
  const [searchValue, setSearchValue] = useState('')

  // Rediriger en fonction de la valeur de recherche
  useEffect(() => {
    const list = {
      NUMERO: searchNumero,
      NOM_PERSONNE: searchNomPersonne,
    }
    setSearchValue(list[searchCategory] ?? '')
  }, [searchCategory, searchNumero, searchNomPersonne])

  const handleChangeCategory = (e) => setSearchCategory(e.target.value)

  // Input
  function handleChangeSearch(e) {
    e.preventDefault()
    if (searchCategory === 'NUMERO') {
      setSearchNumero(e.target.value)
    } else if (searchCategory === 'NOM_PERSONNE') {
      setSearchNomPersonne(forceInputAlpha(searchNomPersonne, e.target.value))
    }
  }

  // Buttons
  function handleSubmit(e) {
    e.preventDefault()
    if (searchValue.length > 0) {
      onSubmit({ searchCategory, searchValue }) // new canSearch
    }
  }

  function handleClear(e) {
    e.preventDefault()
    if (params?.searchValue?.length > 0) {
      onReset()
    }
    // }
  }

  return (
    <div className="search-container">
      <h4>Recherche :</h4>
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="options-writing">
          <select value={searchCategory} onChange={handleChangeCategory}>
            <option value="NUMERO">N° DE PRET</option>
            <option value="NOM_PERSONNE">NOM PERSONNE</option>
          </select>
          {searchCategory === 'NUMERO' && (
            <input type="search" onChange={handleChangeSearch} value={searchNumero} />
          )}
          {searchCategory === 'NOM_PERSONNE' && (
            <input type="search" onChange={handleChangeSearch} value={searchNomPersonne} />
          )}
        </div>
        <div className="action">
          <button
            className="action-button"
            onClick={handleSubmit}
            disabled={searchValue.length === 0}
          >
            Rechercher
          </button>
          <button
            className="action-button reset-button"
            onClick={handleClear}
            disabled={params.searchValue ? params.searchValue.length === 0 : true}
          >
            Réinitialiser
          </button>
        </div>
      </form>
    </div>
  )
}
