import { MdGppGood } from 'react-icons/md'

export default function RenderFormCaisseLocaleEnregistrerAchat({
  handleSubmit,
  paymentTypes,
  remise,
  min,
  max,
  blockedNumCompte,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <label>Numéro de compte</label>
      <input
        name={'p_num_compte'}
        type={'text'}
        required
        {...(blockedNumCompte ? { value: blockedNumCompte, readOnly: true } : {})}
      />

      <label>Montant</label>
      <input name={'p_montant'} type={'number'} required min={min} max={max} />

      <label>Moyen paiement</label>
      <select name={'p_typepaiement'} required>
        {paymentTypes.map((paymentType) => (
          <option value={paymentType.code_m_paiement}>{paymentType.nom_m_paiement}</option>
        ))}
      </select>

      <label>Mise à disposition des unités de compte</label>
      <select name={'p_remisefl'} required {...(remise ? { readOnly: true, value: remise } : {})}>
        <option value="fiduciaire">Fiduciaire</option>
        <option value="compte de dépôt">Compte de dépôt</option>
      </select>

      <button className="valid-btn deposit" type={'submit'}>
        <p>Enregistrement</p>
        <MdGppGood />
      </button>
    </form>
  )
}
