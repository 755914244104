import { PDFViewer } from '@react-pdf/renderer'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { orderBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState, useContext, useRef } from 'react'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { MdCheckCircle, MdStopCircle } from 'react-icons/md'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { amountSuffix } from '../../data/constants/constants'
import { AccountOperationsPDF } from '../../utils/pdf/AccountOperationsPDF'
import { generateDownloadReactPDFDocument } from '../../utils/pdf/utils'
import { AuthContext, getTokenIsLocalAdmin } from '../../utils/providers/AuthContext'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { getUser, getUserDataSelf } from '../../data/services/apiUser'
import {
  getSelfUserTransactions,
  getUserTransactions,
  modifAchat,
  modifDepot,
  updateTransactionAmount,
} from '../../data/services/apiTransaction'
import { formatDate } from '../../utils/functions/timeDate'
import { PopUpOperationsExport } from '../UI/PopUpOperationsExport'
import Transaction from '../UI/Transaction'
import HelpContainer from '../UI/HelpContainer'
import LoadingSpinner from '../UI/LoadingSpinner'
import '../../style/screens/Operation.scss'

export default function Operation({ self = true }) {
  const { id } = useParams()
  const [errorMsg, setErrorMsg] = useState('')
  const [reject, setReject] = useState(false)
  const [user, setUser] = useState([])
  const [transactions, setTransactions] = useState([])
  const { pageLoading, setPageLoading } = usePageAuth()
  const { logOutErrorHandler } = useContext(AuthContext)
  const navigate = useNavigate()
  const [date, setDate] = useState(moment())
  const [dateUpdated, setDateUpdated] = useState(false)
  const [hidden, setHidden] = useState(false)

  const [downloadPopupVisible, setDownloadPopupVisible] = useState(false)

  const [edit, setEdit] = useState(null)

  const editInputRef = useRef(null)

  const fetchUserTransactions = async () => {
    let result = await getUserTransactions(id)
    if (result?.length === 0) {
      setHidden(true)
    }
    setTransactions(result)
  }

  useEffect(() => {
    async function fetchData() {
      setPageLoading(true)
      try {
        let result
        if (self) {
          result = await getUserDataSelf()
          setUser(result)
          result = await getSelfUserTransactions(
            date.subtract('30', 'days').format('YYYY-MM-DD'),
            dateUpdated ? date.add('30', 'days').format('YYYY-MM-DD') : undefined
          )
          if (result?.length === 0) {
            setHidden(true)
          }
          setTransactions((transactions) => [...transactions, ...result])
        } else {
          result = await getUser(id)
          setUser(result)
          fetchUserTransactions()
        }
      } catch (error) {
        if (logOutErrorHandler(error)) {
          return
        }
        setErrorMsg(error.message)
      }
      setPageLoading(false)
    }
    if (getTokenIsLocalAdmin()) {
      setErrorMsg("Vous n'êtes pas autorisé")
      setReject(true)
    } else {
      fetchData()
    }
  }, [date])

  const downloadPdf = async (from, to) => {
    const fromDate = moment(from).format('YYYY-MM-DD')
    const toDate = moment(to).add(1, 'day').format('YYYY-MM-DD')
    let operations = []
    if (self) {
      operations = await getSelfUserTransactions(fromDate, toDate)
    } else {
      operations = await getUserTransactions(id, fromDate, toDate)
    }
    operations = orderBy(operations, (o) => moment(o.date), ['desc'])
    operations = generateDownloadReactPDFDocument(
      <AccountOperationsPDF
        operations={operations}
        userId={user.id}
        from={from}
        to={to}
        user={user}
        self={self}
      />
    )
  }

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button
        className="back-button-edit"
        onClick={() => {
          if (reject) {
            navigate('/compte')
          } else {
            setErrorMsg('')
          }
        }}
      >
        Retour
      </button>
    </main>
  ) : (
    <>
      {downloadPopupVisible && (
        <PopUpOperationsExport
          self={self}
          user={user}
          onClose={() => setDownloadPopupVisible(false)}
          download={downloadPdf}
        />
      )}
      {!self && <h2>COMPTE {user.accountNumber}</h2>}
      <HelpContainer
        links={[
          {
            img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_synthese.png',
            text: self ? 'Mon compte' : 'Retour au compte',
            path: self ? '/compte' : `/utilisateur/${id}`,
          },
          ...(self
            ? [
                {
                  img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_compte.png',
                  text: 'Mes bénéficiaires',
                  path: '/beneficiaires',
                },
              ]
            : []),
        ]}
      />
      <main className="personnal-operation">
        <div className="texts-blocs">
          <h2 className="operation-span-text">
            {self ? 'Mes dernières opérations' : 'Dernières opérations'}
          </h2>
          {self && (
            <div>
              <Transaction refresh={() => setDate(moment())} />
            </div>
          )}
          <div className={'export-btn-container'}>
            <AiOutlineFilePdf onClick={() => setDownloadPopupVisible(true)} />
          </div>
          <div className="operation-account">
            <div className="grid-row head">
              {/*<div className="grid-cell">Compte</div>*/}
              <div className="grid-cell">Date</div>
              <div className="grid-cell">Tiers</div>
              <div className="grid-cell">Nature de l'opération</div>
              <div className="grid-cell">Montant</div>
            </div>

            {transactions
              .sort((a, b) => b.time - a.time)
              .map((transaction, index) => {
                const userIsSender = user.id === transaction.from
                const userIsReceiver = user.id === transaction.to
                return (
                  <div
                    className={`grid-row ${
                      !self && ['achat', 'vente', 'dépôt', 'retrait'].includes(transaction.nature)
                        ? 'user'
                        : ''
                    }`}
                    key={transaction.id}
                    onClick={() =>
                      !self &&
                      !edit &&
                      ['achat', 'vente', 'dépôt', 'retrait'].includes(transaction.nature) &&
                      setEdit(transaction.id)
                    }
                  >
                    {/*<div className="grid-cell">{transaction.account}</div>*/}
                    <div className="grid-cell">{formatDate(transaction.time, 'D/M/YY')}</div>
                    <div className="grid-cell">{transaction.tier}</div>

                    <div className="grid-cell name">
                      <div className="mobile date">{formatDate(transaction.time, 'D/M/YY')}</div>
                      <span>
                        {transaction.nature +
                          (transaction.id && transaction.id !== 'N/A'
                            ? ' (' + transaction.id + ')'
                            : '')}
                      </span>
                      <div className={`mobile amount ${userIsReceiver ? 'plus' : 'minus'}`}>
                        {userIsSender && '-'}
                        {userIsReceiver && '+'}
                        {transaction.amount + amountSuffix}
                      </div>
                    </div>

                    <div
                      className={`grid-cell desktop-amount ${userIsReceiver ? 'plus' : 'minus'}`}
                    >
                      {edit === transaction.id ? (
                        <>
                          <div className={'edit-amount'}>
                            <input
                              ref={editInputRef}
                              type={'number'}
                              defaultValue={transaction.amount}
                              placeholder={'Nouveau montant'}
                              className={'edit-amount-input'}
                            />
                            <div className={'icons'}>
                              <MdCheckCircle
                                color={'green'}
                                onClick={() => {
                                  if (editInputRef.current) {
                                    if (
                                      transaction.nature === 'achat' ||
                                      transaction.nature === 'vente'
                                    ) {
                                      modifAchat(
                                        transaction.id_opération,
                                        editInputRef.current.value
                                      )
                                    } else if (
                                      transaction.nature === 'dépôt' ||
                                      transaction.nature === 'retrait'
                                    ) {
                                      modifDepot(
                                        transaction.account,
                                        transaction.date,
                                        editInputRef.current.value
                                      )
                                    }
                                    updateTransactionAmount(
                                      'operations' + id,
                                      transaction.id,
                                      editInputRef.current.value
                                    )
                                    setEdit(false)
                                    fetchUserTransactions()
                                  }
                                }}
                              />
                              <MdStopCircle color={'#c0301e'} onClick={() => setEdit(null)} />
                            </div>
                          </div>
                        </>
                      ) : (
                        <span>
                          {userIsSender && '-'}
                          {userIsReceiver && '+'}
                          {transaction.amount + amountSuffix}
                        </span>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
          {!hidden && self && (
            <div
              className={'load-more'}
              onClick={() => {
                setDate((date) => moment(date).subtract('30', 'days'))
                setDateUpdated(true)
              }}
            >
              Charger plus
              <br />▼
            </div>
          )}
        </div>
      </main>
      <HelpContainer
        links={[
          /*{
            img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_in_situ.png',
            text: 'Où utiliser les francs libres ?',
            path: '/enseignes',
          },*/
          {
            img: process.env.REACT_APP_PUBLIC_URL + '/images/icons/bleu_mar_hotline.png',
            text: "Besoin d'aide ? Contactez-nous",
            path: '/contact',
          },
        ]}
      />
    </>
  )
}
