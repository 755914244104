import qs from 'qs'
import { MdGppGood } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'

export default function RenderFormCaisseLocaleEnregistrerOperateurPersonne({
  handleSubmit,
  id_personne,
  selectedCaisse,
  caisses = [],
  setCaisse,
  verrous,
  responsabilites,
  selectedResponsabilite,
  setSelectedResponsabilite,
}) {
  const { codeResp, caisse } = qs.parse(useSearchParams()[0].toString())

  return (
    <form onSubmit={handleSubmit} className={'subscriptionForm'}>
      <label>ID Personne</label>
      <input
        name={'p_id_personne'}
        type={'text'}
        required
        readOnly
        disabled={!!codeResp && !!caisse}
        value={id_personne}
      />

      <label>Caisse</label>
      <select
        disabled={!!caisse}
        name={'p_code_caisse'}
        required
        value={selectedCaisse}
        onChange={(e) => setCaisse(e.target.value)}
      >
        {caisses.map((caisse) => (
          <option value={caisse.code_caisse}>{caisse.nom_caisse}</option>
        ))}
      </select>

      <label>Responsabilité</label>
      <select
        name={'p_code_responsabilité'}
        required
        disabled={!!codeResp}
        value={selectedResponsabilite}
        onChange={(e) => setSelectedResponsabilite(e.target.value)}
      >
        {responsabilites.map((responsabilite) => {
          const verrou = verrous.find(
            (verrou) => verrou.code_responsabilité === responsabilite.code_responsabilité
          )
          return (
            <option value={responsabilite.code_responsabilité}>
              {responsabilite.nom_responsabilité +
                (verrou
                  ? ' - ' + verrou.nom_personnephysique + ' ' + verrou.prénom_personnephysique
                  : '')}
            </option>
          )
        })}
      </select>

      <label>Début de mandat</label>
      <input name={'p_date_début'} type={'date'} required />

      <button className="valid-btn deposit" type={'submit'}>
        <p>Enregistrement</p>
        <MdGppGood />
      </button>
    </form>
  )
}
