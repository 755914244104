import axios from 'axios'
import { useEffect, useState } from 'react'
import { MdGppGood } from 'react-icons/md'
import '../../style/screens/CaisseLocale.scss'
import { baseUrl } from '../../data/constants/constants'
import { adhHeaders } from '../../data/services/api'

export default function RenderFormDeposit({ handleSubmit, defaultEmail, type, setErrorMsg }) {
  const [paymentTypes, setPaymentTypes] = useState([])

  useEffect(() => {
    if (type === 'achat') {
      axios
        .get(`${baseUrl}/v_moyens_paiement`, { headers: adhHeaders() })
        .then((r) => {
          setPaymentTypes(r.data)
        })
        .catch((e) => {
          setErrorMsg('Une erreur est survenue lors de la récupération des moyens de paiement')
        })
    }
  }, [type])

  return (
    <form onSubmit={handleSubmit} method={'POST'}>
      <label>Montant</label>
      <input name={'amount'} type={'number'} required />

      <label>Courriel</label>
      <input defaultValue={defaultEmail} name={'email'} type={'email'} required />

      {type === 'achat' && (
        <>
          <label>N° de transaction</label>
          <input name={'transactionId'} type={'text'} required />

          <label>Type</label>
          <select name={'type'} required>
            {paymentTypes.map((paymentType) => (
              <option value={paymentType.code_m_paiement}>{paymentType.nom_m_paiement}</option>
            ))}
          </select>

          <label>Type d'opération</label>
          <select name={'operationType'} required>
            <option value="fiducaire">Fiducaire</option>
            <option value="compte de dépôt">Compte de dépôt</option>
          </select>
        </>
      )}

      <button className="valid-btn deposit">
        <p>{type === 'achat' ? 'Achat/Vente' : 'Dépôt/Retrait'}</p>
        <MdGppGood />
      </button>
    </form>
  )
}
