import axios from 'axios'
import moment from 'moment/moment'
import qs from 'qs'
import React, { useContext, useEffect, useState } from 'react'
import { AiFillLock, AiFillUnlock, AiOutlineCheck } from 'react-icons/ai'
import { FaCheck } from 'react-icons/fa'
import { FiSkipBack } from 'react-icons/fi'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { baseUrl, currencyCode, currencySymbol, ranks } from '../../data/constants/constants'
import { adhHeaders } from '../../data/services/api'
import {
  clearCaisseCache,
  clearCaisseWithIdCache,
  deverroullerCaisse,
  enregistrerAchat,
  enregistrerDepot,
  enregistrerOperateur,
  getCaisse,
  getCaisseWithId,
  getResponsabilites,
} from '../../data/services/apiCaisse'
import { getAccountWithId, getUserDataSelf } from '../../data/services/apiUser'
import '../../style/screens/CaisseLocale.scss'
import '../../style/screens/UserList.scss'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import usePrevious from '../../utils/hooks/usePrevious'
import { AuthContext } from '../../utils/providers/AuthContext'
import { useSMSVerificationContext } from '../../utils/providers/SMSVerificationContext'
import RenderFormCaisseLocaleEnregistrerAchat from '../../utils/renders/RenderFormCaisseLocaleEnregistrerAchat'
import RenderFormCaisseLocaleEnregistrerDepot from '../../utils/renders/RenderFormCaisseLocaleEnregistrerDepot'
import RenderFormCaisseLocaleEnregistrerOperateur from '../../utils/renders/RenderFormCaisseLocaleEnregistrerOperateur'
import LoadingSpinner from '../UI/LoadingSpinner'

const finalStatus = [
  'enregistrement_comptoir_achat',
  'enregistrement_comptoir_vente',
  'enregistrement_compte_existant_achat',
  'enregistrement_compte_existant_depot',
  'enregistrement_compte_existant_vente',
  'enregistrement_compte_existant_retrait',
]

const status = [
  'enregistrement',
  'enregistrement_comptoir',
  'enregistrement_compte_existant',
  ...finalStatus,
]

export const CaisseLocale = () => {
  const { isConnected, logOutErrorHandler } = useContext(AuthContext)
  const { pageLoading, setPageLoading } = usePageAuth()
  const [errorMsg, setErrorMsg] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [successMsg, setSuccessMsg] = useState('')

  const { id, codeResp } = useParams()

  const [paymentTypes, setPaymentTypes] = useState([])

  const [searchParams, setSearchParams] = useSearchParams()
  const enregistrementStatus = searchParams.get('status')
  const previousEnregistrementStatus = usePrevious(enregistrementStatus)

  const [user, setUser] = useState()

  const [verrous, setVerrous] = useState([])
  const [operations, setOperations] = useState([])
  const [soldes, setSoldes] = useState([])
  const [etatVerrou, setEtatVerrou] = useState(null)
  const [responsabilites, setResponsabilites] = useState([])

  const { RenderSMSVerification, inVerification, setInVerification } = useSMSVerificationContext()

  const fetchCaisse = async () => {
    try {
      const [verrous, operations, soldes, verrou] = await (id ? getCaisseWithId : getCaisse)(id)
      setVerrous(verrous.data)
      setOperations(operations.data)
      setSoldes(soldes.data)
      if (verrou) {
        setEtatVerrou(verrou.data?.[0]?.etat_verrou ?? null)
      }
      if (id) {
        const responsabilites = await getResponsabilites()
        setResponsabilites(responsabilites)
      }
    } catch (e) {
      setErrorMsg('Une erreur est survenue pendant la récupération de la caisse locale')
    }
  }

  useEffect(() => {
    setPageLoading(true)
    if (!id) {
      axios
        .get(`${baseUrl}/v_moyens_paiement`, { headers: adhHeaders() })
        .then((r) => {
          setPaymentTypes(r.data)
        })
        .catch((e) => {
          setErrorMsg('Une erreur est survenue lors de la récupération des moyens de paiement')
        })
    }
    getUserDataSelf().then(async (user) => {
      if (
        user &&
        ((id && user.level === ranks.ADMIN) ||
          (user.caisse && ['TRS', 'ELU', 'SEC'].includes(user?.caisse?.role)))
      ) {
        setUser(user)
        await fetchCaisse()
        setPageLoading(false)
      } else {
        navigate('/compte')
        setPageLoading(false)
      }
    })
  }, [location])

  const unlockCaisseSuccess = async () => {
    setPageLoading(true)
    await fetchCaisse()
    setPageLoading(false)
  }

  const unlockCaisse = async () => {
    setPageLoading(true)
    const r = await deverroullerCaisse()
    if (r === 'SMS') {
      setPageLoading(false)
      setInVerification(true)
      return
    }
    unlockCaisseSuccess()
  }

  const enregistrementSuccess = () => {
    setPageLoading(false)
    searchParams.delete('status')
    setSearchParams(searchParams)
    clearCaisseCache()
    fetchCaisse()
  }

  const handleSMSVerification = async (promise) => {
    const result = await promise
    if (result?.[0]?.code === 'SMS Envoyé') {
      setPageLoading(false)
      setInVerification(true)
      return
    }
    enregistrementSuccess()
  }

  const achat = async (e) => {
    e.preventDefault()
    setPageLoading(true)
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)
    handleSMSVerification(enregistrerAchat(data))
  }

  const depot = async (e) => {
    e.preventDefault()
    setPageLoading(true)
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)
    handleSMSVerification(enregistrerDepot(data))
  }

  const operateur = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const { numéro_compte } = Object.fromEntries(formData)

    getAccountWithId(numéro_compte)
      .catch((e) => setErrorMsg("Une erreur est survenue pendant l'enregistrement de l'opérateur"))
      .then((user) => {
        navigate(
          `/utilisateur/${user.id_personne}/delegation?${qs.stringify({ codeResp, caisse: id })}`
        )
      })

    /*await enregistrerOperateur({
      p_numéro_ss: numéro_ss,
      p_code_caisse: id,
      p_code_responsabilité: codeResp,
    }).catch((e) => setErrorMsg("Une erreur est survenue pendant l'enregistrement de l'opérateur"))
    clearCaisseWithIdCache(id)
    navigate('/delegation/' + id)
    fetchCaisse()*/
  }

  if (inVerification) {
    return (
      <main className="page-msg">
        <RenderSMSVerification
          onSuccess={
            status.includes(enregistrementStatus) ? enregistrementSuccess : unlockCaisseSuccess
          }
        />
      </main>
    )
  }

  console.log(previousEnregistrementStatus)

  if (!pageLoading && !errorMsg && !successMsg && status.includes(enregistrementStatus)) {
    const render = {
      enregistrement: (
        <div className={'enregistrement'}>
          <div onClick={() => navigate('?status=enregistrement_comptoir')}>Comptoir</div>
          <div onClick={() => navigate('?status=enregistrement_compte_existant')}>
            Compte existant
          </div>
        </div>
      ),
      enregistrement_comptoir: (
        <div className={'enregistrement'}>
          <div onClick={() => navigate('?status=enregistrement_comptoir_achat')}>
            Achat de FL depuis EUR
          </div>
          {/* div onClick={() => navigate('?status=enregistrement_comptoir_vente')}>Vente de FL vers EUR</div> */}
        </div>
      ),
      enregistrement_compte_existant: (
        <div className={'enregistrement'}>
          <div onClick={() => navigate('?status=enregistrement_compte_existant_achat')}>
            Achat de FL depuis EUR
          </div>
          {/* <div onClick={() => navigate('?status=enregistrement_compte_existant_vente')}>Vente de FL vers EUR</div>*/}
          <div onClick={() => navigate('?status=enregistrement_compte_existant_depot')}>
            Dépôt de FL
          </div>
          <div onClick={() => navigate('?status=enregistrement_compte_existant_retrait')}>
            Retrait de FL
          </div>
        </div>
      ),
      enregistrement_comptoir_achat: (
        <div className={'enregistrement_form'}>
          <RenderFormCaisseLocaleEnregistrerAchat
            paymentTypes={paymentTypes}
            handleSubmit={achat}
            min={1}
            remise={'fiduciaire'}
            blockedNumCompte={'C000000001'}
          />
        </div>
      ),
      enregistrement_comptoir_vente: (
        <div className={'enregistrement_form'}>
          <RenderFormCaisseLocaleEnregistrerAchat
            paymentTypes={paymentTypes}
            handleSubmit={achat}
            max={-1}
            remise={'fiduciaire'}
            blockedNumCompte={'C000000001'}
          />
        </div>
      ),
      enregistrement_compte_existant_achat: (
        <div className={'enregistrement_form'}>
          <RenderFormCaisseLocaleEnregistrerAchat
            paymentTypes={paymentTypes}
            handleSubmit={achat}
            min={1}
          />
        </div>
      ),
      enregistrement_compte_existant_depot: (
        <div className={'enregistrement_form'}>
          <RenderFormCaisseLocaleEnregistrerDepot handleSubmit={depot} min={1} />
        </div>
      ),
      enregistrement_compte_existant_vente: (
        <div className={'enregistrement_form'}>
          <RenderFormCaisseLocaleEnregistrerAchat
            paymentTypes={paymentTypes}
            handleSubmit={achat}
            max={-1}
          />
        </div>
      ),
      enregistrement_compte_existant_retrait: (
        <div className={'enregistrement_form'}>
          <RenderFormCaisseLocaleEnregistrerDepot handleSubmit={depot} max={-1} />
        </div>
      ),
    }
    return (
      <>
        <h2>
          Enregistrement d'une opération
          <br />
          {user.caisse.nom}
        </h2>
        <main className={'caisse-locale'}>
          <button
            className="back-btn"
            onClick={() =>
              navigate(
                '/caisse-locale' +
                  (finalStatus.includes(enregistrementStatus)
                    ? '?status=' +
                      (previousEnregistrementStatus === enregistrementStatus
                        ? 'enregistrement'
                        : previousEnregistrementStatus)
                    : enregistrementStatus === 'enregistrement'
                    ? ''
                    : '?status=enregistrement')
              )
            }
          >
            <FiSkipBack />
          </button>
          {render[enregistrementStatus]}
        </main>
      </>
    )
  }

  if (
    !pageLoading &&
    !errorMsg &&
    !successMsg &&
    codeResp &&
    responsabilites.some((responsabilite) => responsabilite.code_responsabilité === codeResp)
  ) {
    const verrou = verrous.find((verrou) => verrou.code_responsabilité === codeResp)
    return (
      <>
        <h2>
          Enregistrement d'un opérateur de caisse
          <br />
          Caisse {id}
          <br />
          {
            responsabilites.find(
              (responsabilite) => responsabilite.code_responsabilité === codeResp
            ).nom_responsabilité
          }
        </h2>
        <h2 className={'responsable'}>
          {verrou ? (
            <>
              Responsable actuel :{' '}
              {verrou.nom_personnephysique + ' ' + verrou.prénom_personnephysique} (
              {verrou.id_personne})
            </>
          ) : (
            'Actuellement aucun responsable'
          )}
        </h2>
        <main className={'caisse-locale'}>
          <button
            className="back-btn"
            onClick={() => {
              navigate('/delegation/' + id)
            }}
          >
            <FiSkipBack />
          </button>
          <div className={'edit-verrou'}>
            <RenderFormCaisseLocaleEnregistrerOperateur handleSubmit={operateur} />
          </div>
        </main>
      </>
    )
  }

  console.log(verrous)

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button className="back-button-edit" onClick={() => setErrorMsg('')}>
        Retour
      </button>
    </main>
  ) : successMsg ? (
    <main className="page-msg">
      <p>{successMsg}</p>
    </main>
  ) : (
    <>
      <h2>Entité : {id ? 'Caisse ' + id : user.caisse.nom}</h2>
      <main className="caisse-locale">
        {id && (
          <button
            className="back-btn"
            onClick={() => {
              navigate('/delegation')
            }}
          >
            <FiSkipBack />
          </button>
        )}
        <div>
          <div className={'solde'}>
            Solde en euro: {soldes.find((solde) => solde.code_monnaie === 'EUR')?.somme ?? 0}€<br />
            Solde en unité de compte:{' '}
            {soldes.find((solde) => solde.code_monnaie === currencyCode)?.somme ?? 0}
            {currencySymbol}
          </div>
          {!id && (
            <div className={'buttons-container'}>
              <button
                className="back-btn souscription-pret"
                disabled={etatVerrou !== 'verrouillé'}
                onClick={(e) => !e.currentTarget.disabled && unlockCaisse()}
              >
                Déverrouiller la caisse
              </button>
              <button
                className="back-btn suppression-compte"
                disabled={
                  verrous.some((verrou) => verrou.etat_verrou !== 'déverouillé') ||
                  user?.caisse?.role !== 'TRS'
                }
                onClick={(e) => !e.currentTarget.disabled && navigate('?status=enregistrement')}
              >
                Enregistrer une opération
              </button>
            </div>
          )}
        </div>
        <div className="table-container">
          <h3>Verrous</h3>
          {verrous.length > 0 ? (
            <table>
              <tbody>
                <tr>
                  <th>#</th>
                  <th>ID Personne</th>
                  <th>Personne</th>
                  <th>Responsabilité</th>
                  <th>Prise de fonction</th>
                  <th>Fin de mandat</th>
                  <th>Etat</th>
                </tr>

                {verrous.map((verrou, index) => {
                  const Row = ({ children }) => {
                    if (id) {
                      return (
                        <Link
                          to={'/delegation/' + id + '/' + verrou.code_responsabilité}
                          className={'table-row'}
                        >
                          {children}
                        </Link>
                      )
                    }
                    return <tr>{children}</tr>
                  }
                  return (
                    <Row>
                      <th>{index + 1}</th>
                      <td>{verrou.id_personne}</td>
                      <td>{verrou.nom_personnephysique + ' ' + verrou.prénom_personnephysique}</td>
                      <td>{verrou.nom_responsabilité}</td>
                      <td>
                        {verrou.date_prise_fonction || verrou.date_début_mandat
                          ? moment(verrou.date_prise_fonction ?? verrou.date_début_mandat).format(
                              'DD/MM/YYYY'
                            )
                          : ''}
                      </td>
                      <td>
                        {verrou.date_fin_mandat || verrou.date_fiin_mandat
                          ? moment(verrou.date_fin_mandat ?? verrou.date_fiin_mandat).format(
                              'DD/MM/YYYY'
                            )
                          : ''}
                      </td>
                      <td className={'verrou'}>
                        {verrou.etat_verrou === 'verrouillé' ? <AiFillLock /> : <AiOutlineCheck />}
                        {verrou.etat_verrou}
                      </td>
                    </Row>
                  )
                })}
                {id &&
                  responsabilites
                    .filter(
                      (responsabilite) =>
                        !verrous.some(
                          (verrou) =>
                            verrou.code_responsabilité === responsabilite.code_responsabilité
                        )
                    )
                    .map((responsabilite, index) => (
                      <Link
                        to={'/delegation/' + id + '/' + responsabilite.code_responsabilité}
                        className={'table-row'}
                      >
                        <th>{verrous.length + (index + 1)}</th>
                        <td>-</td>
                        <td>-</td>
                        <td>{responsabilite.nom_responsabilité}</td>
                        <td></td>
                      </Link>
                    ))}
              </tbody>
            </table>
          ) : (
            <p className="msg-no-account">Aucun verrou ne correspond à la recherche</p>
          )}
        </div>
        <div className="table-container">
          <h3>Opérations</h3>
          {operations.length > 0 ? (
            <table>
              <tbody>
                <tr>
                  <th>#</th>
                  <th>N° compte</th>
                  <th>Nature</th>
                  <th>Montant</th>
                  <th>Date</th>
                </tr>

                {operations.map((operation, index) => {
                  return (
                    <tr>
                      <th>{index}</th>
                      <td>{operation.numéro_compte}</td>
                      <td>{operation.nature}</td>
                      <td>{operation.montant}</td>
                      <td>{moment(operation.date_opération)?.format('DD/MM/YYYY')}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <p className="msg-no-account">Aucune opération ne correspond à la recherche</p>
          )}
        </div>
      </main>
    </>
  )
}
