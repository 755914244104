import React from 'react'

import { Text, View } from '@react-pdf/renderer'

const DEFAULT_CELL_PADDING = 4

export function getDefaultBorderIncludes(border) {
  return {
    includeBottomBorder:
      border.includeBottomBorder === undefined ? true : border.includeBottomBorder,
    includeTopBorder: border.includeTopBorder === undefined ? true : border.includeTopBorder,
    includeLeftBorder: border.includeLeftBorder === undefined ? true : border.includeLeftBorder,
    includeRightBorder: border.includeRightBorder === undefined ? true : border.includeRightBorder,
  }
}

export function transformToArray(items) {
  if (items === undefined) {
    return []
  }

  if (Array.isArray(items)) {
    return items
  }

  return [items]
}

export class DataTableCell extends React.PureComponent {
  render() {
    return <TableCell {...this.props}>{this.props.getContent(this.props.data)}</TableCell>
  }
}

export class TableCell extends React.PureComponent {
  render() {
    let content

    if (typeof this.props.children === 'string') {
      content = <Text>{this.props.children}</Text>
    } else if (typeof this.props.children === 'number') {
      content = <Text>{this.props.children.toString()}</Text>
    } else {
      content = this.props.children
    }

    const { includeRightBorder } = getDefaultBorderIncludes(this.props)
    const defaultStyle = {
      flex: this.props.weighting ?? 1,
      justifyContent: 'stretch',
      textAlign: this.props.textAlign ?? 'center',
      fontSize: this.props.fontSize ?? (this.props.isHeader === true ? 12 : 10),
      borderRight: includeRightBorder && `1pt solid ${'grey'}`,
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      backgroundColor: this.props.isHeader && 'grey',
      color: this.props.isHeader && 'white',
      padding: DEFAULT_CELL_PADDING,
      overflow: 'hidden',
    }

    let customStyle
    if (this.props.getCustomStyle && this.props.data) {
      customStyle = this.props.getCustomStyle(this.props.data)
    }

    const mergedStyles = [
      defaultStyle,
      ...transformToArray(this.props.rowStyle),
      ...transformToArray(this.props.style),
      ...transformToArray(customStyle),
    ]

    return (
      <View style={mergedStyles} wrap>
        {content}
      </View>
    )
  }
}

export class TableRow extends React.PureComponent {
  render() {
    const rowCells = React.Children.toArray(this.props.children)
    const { includeLeftBorder, includeBottomBorder, includeRightBorder, includeTopBorder } =
      getDefaultBorderIncludes(this.props)

    let remainingWeighting = 1
    let numberOfWeightingsDefined = 0
    rowCells.forEach((i) => {
      if (i.props.weighting !== undefined) {
        remainingWeighting -= i.props.weighting
        numberOfWeightingsDefined++
      }
    })

    const weightingsPerNotSpecified = Math.ceil(
      remainingWeighting / (rowCells.length - numberOfWeightingsDefined)
    )
    const backgroundColor = this.props.backgroundColorToAlternate
      ? this.props.rowIndex % 2 == 0
        ? {
            backgroundColor: this.props.backgroundColorToAlternate,
          }
        : {
            backgroundColor: 'white',
          }
      : {}

    const borderColor = this.props.borderColor ?? 'grey'

    return (
      <View
        style={{
          borderBottom: includeBottomBorder && `1pt solid ${borderColor}`,
          borderRight: includeRightBorder && `1pt solid ${borderColor}`,
          borderLeft: includeLeftBorder && `1pt solid ${borderColor}`,
          borderTop: includeTopBorder && `1pt solid ${borderColor}`,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'stretch',
          ...backgroundColor,
        }}
      >
        {rowCells.map((rc, columnIndex) =>
          React.cloneElement(rc, {
            weighting: rc.props.weighting ?? weightingsPerNotSpecified,
            data: this.props.data,
            key: columnIndex,
            fontSize: this.props.fontSize,
            textAlign: this.props.textAlign,
            includeLeftBorder: columnIndex === 0,
            includeRightBorder: columnIndex !== rowCells.length - 1,
          })
        )}
      </View>
    )
  }
}

export class TableBody extends React.PureComponent {
  render() {
    const rowCells = React.Children.toArray(this.props.children)
    const { includeLeftBorder, includeBottomBorder, includeRightBorder } = getDefaultBorderIncludes(
      this.props
    )
    const dataRows = this.props.data ?? []

    return dataRows.map((data, rowIndex) => (
      <TableRow
        {...this.props}
        key={rowIndex}
        rowIndex={rowIndex}
        data={data}
        includeLeftBorder={includeLeftBorder}
        includeBottomBorder={includeBottomBorder}
        includeRightBorder={includeRightBorder}
        includeTopBorder={false}
      >
        {rowCells}
      </TableRow>
    ))
  }
}

export class TableHeader extends React.PureComponent {
  render() {
    const { includeLeftBorder, includeBottomBorder, includeRightBorder, includeTopBorder } =
      getDefaultBorderIncludes(this.props)

    const rowCells = React.Children.toArray(this.props.children)

    return (
      <TableRow
        {...this.props}
        key="header"
        includeLeftBorder={includeLeftBorder}
        includeBottomBorder={includeBottomBorder}
        includeRightBorder={includeRightBorder}
        includeTopBorder={includeTopBorder}
      >
        {rowCells.map((rc, columnIndex) =>
          React.cloneElement(rc, {
            key: columnIndex,
            isHeader: true,
            fontSize: this.props.fontSize,
            textAlign: this.props.textAlign,
            includeLeftBorder: columnIndex === 0,
            includeRightBorder: columnIndex !== rowCells.length - 1,
          })
        )}
      </TableRow>
    )
  }
}

export class Table extends React.PureComponent {
  render() {
    let tableHeader = null
    let tableBody = null

    React.Children.forEach(this.props.children, (c) => {
      if (c.type === TableHeader) {
        tableHeader = c
      } else if (c.type === TableBody) {
        tableBody = React.cloneElement(c, {
          data: c.props.data ?? this.props.data ?? [],
        })
      }
    })

    return (
      <View
        style={{
          width: '100%',
        }}
      >
        {tableHeader}
        {tableBody}
      </View>
    )
  }
}
