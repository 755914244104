import queryString from 'query-string'
import { useContext } from 'react'
import {
  editUserPhone,
  editUserSS,
  getUserDataAdmin,
  supprimerCompte,
} from '../../data/services/apiUser'
import { createUser, changeUserAccount } from '../../data/services/apiUser'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { FiSkipBack } from 'react-icons/fi'
import { MdGppGood } from 'react-icons/md'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { forceInput, forceInputAlpha, forceInputNumber } from '../../utils/functions/forceInput'
import { timestampToDate } from '../../utils/functions/timeDate'
import { ranks } from '../../data/constants/constants'
import {
  AuthContext,
  getTokenIsAdmin,
  getTokenIsController,
} from '../../utils/providers/AuthContext'
import isAlpha from 'validator/lib/isAlpha'
import RenderFormUser from '../../utils/renders/RenderFormUser'
import '../../style/screens/UserEdit.scss'

export default function UserEdit() {
  const location = useLocation()
  const {
    firstname = '',
    lastname = '',
    ss: ssQuery = '',
    indicatif: indicatifQuery = '',
    phone: phoneQuery = '',
    numeroCompte = '',
    from,
  } = queryString.parse(location.search)

  const [ss, setSs] = useState(ssQuery)
  const [indicatif, setIndicatif] = useState(indicatifQuery)
  const [phoneNumber, setPhoneNumber] = useState(phoneQuery)
  const [inputErrors, setInputErrors] = useState({})
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [reject, setReject] = useState(false)
  const [memUser, setMemUser] = useState({})
  const { pageLoading, setPageLoading } = usePageAuth()
  const { logOutErrorHandler } = useContext(AuthContext)
  const [isAdmin, setIsAdmin] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const isAdminToken = getTokenIsAdmin()
    // console.log({ isAdminToken })
    setIsAdmin(isAdminToken)
    if (!isAdminToken && !getTokenIsController()) {
      setReject(true)
      setErrorMsg("Vous n'êtes pas autorisé")
    }
  }, []) // gestion de page

  const handleChangeSs = (e) => setSs(forceInputNumber(ss, e.target.value, 15))
  const handleChangeIndicatif = (e) => setIndicatif(forceInputNumber(indicatif, e.target.value, 3))
  const handleChangePhoneNumber = (e) =>
    setPhoneNumber(forceInputNumber(phoneNumber, e.target.value, 9))

  function canSubmit() {
    // possible succès de publier
    const errors = {
      ss: '',
    }
    if (ss === '') {
      errors.ss = 'Ce champ ne doit pas être vide'
    }
    if (phoneNumber === '' || indicatif === '') {
      errors.phoneNumber = 'Ce champ ne doit pas être vide'
    }
    // Validation birthday - Info n'a pas de validation - Email a validé seulement si non vide - Validation Password spécial à mettre en place seulement si ID existe
    setInputErrors(errors)
    let success = Object.values(errors).every((el) => el.length === 0) // passe toutes les valeurs en vérifiant que ce sont des bien chaînes de caractère vides (bouléen vrai)
    return success
  }

  async function handleSubmit(e) {
    e.preventDefault()
    // console.log(canSubmit(), inputErrors)
    if (!canSubmit()) {
      return
    }
    try {
      await editUserSS(id, ss)
      await editUserPhone(id, indicatif, phoneNumber)
      navigate('/liste-utilisateurs')
    } catch (error) {
      setErrorMsg(error.message)
    }
  }

  function keepChangesOnly(body) {
    const entries = Object.entries(body).filter(([key]) => {
      return body[key] !== memUser[key]
    })
    const newBody = Object.fromEntries(entries)
    // console.log(Object.fromEntries(newBody)); // console.log(memUser.birthday, '1')
    if (memUser.birthday === newBody.birthday * 1000) {
      delete newBody.birthday
    }
    return newBody
  }

  const deleteAccount = async () => {
    if (window.confirm('Êtes-vous certain de vouloir supprimer ce compte ?')) {
      try {
        await supprimerCompte(id)
        navigate('/liste-utilisateurs')
      } catch (e) {
        setErrorMsg(
          e?.response?.data?.message ??
            "Une erreur est survenue pendant la suppression de l'utilisateur"
        )
      }
    }
  }

  return pageLoading ? (
    <main className="page-msg">
      <p>Chargement..</p>
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p className="text-align-c">{errorMsg}</p>
      <button
        className="back-button-edit"
        onClick={() => {
          if (reject) {
            navigate('/compte')
          } else {
            setErrorMsg('')
          }
        }}
      >
        {/* <button className="back-button-edit" onClick={() => navigate('/compte')}> */}
        Retour
      </button>
    </main>
  ) : successMsg ? (
    <main className="page-msg">
      <p>{successMsg}</p>
    </main>
  ) : (
    <>
      <h2>Informations du profil : {id}</h2>
      <main className="personnal-profile">
        <div className="text-blocs">
          <div className="all-blocs">
            <div className="user-edit">
              <div>
                <div className={'buttons-container'}>
                  <Link to={from ?? '/liste-utilisateurs'}>
                    <button className="back-btn">
                      <FiSkipBack />
                    </button>
                  </Link>
                  {isAdmin && (
                    <>
                      <Link
                        to={
                          '/souscription-pret/' +
                          id +
                          '?' +
                          new URLSearchParams(queryString.parse(location.search))
                        }
                      >
                        <button className="back-btn souscription-pret">
                          Souscription d'un prêt garanti
                        </button>
                      </Link>
                      <button
                        className="back-btn suppression-compte"
                        onClick={() => deleteAccount()}
                      >
                        Supprimer le compte
                      </button>
                      <Link to={'/utilisateur/' + id + '/delegation'}>
                        <button className="back-btn delegation">Délégation</button>
                      </Link>
                    </>
                  )}
                </div>
                <RenderFormUser
                  handleSubmit={handleSubmit}
                  ss={ss}
                  indicatif={indicatif}
                  phoneNumber={phoneNumber}
                  name={lastname}
                  surname={firstname}
                  handleChangeSs={handleChangeSs}
                  handleChangeIndicatif={handleChangeIndicatif}
                  handleChangePhoneNumber={handleChangePhoneNumber}
                  isAdmin={isAdmin}
                  id={id}
                  inputErrors={inputErrors}
                  MdGppGood={MdGppGood}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
