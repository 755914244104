import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import '../../style/screens/UserEdit.scss'
import { FiSkipBack } from 'react-icons/fi'
import { Link, useLocation } from 'react-router-dom'
import LoadingSpinner from '../../component/UI/LoadingSpinner'
import { smsValidation } from '../../data/services/apiUser'
import RenderFormDeposit from '../renders/RenderFormDeposit'
import { RenderSMSVerificationForm } from '../renders/RenderSMSVerificationForm'

const SMSVerificationContext = createContext({
  inVerification: false,
  setInVerification: (value) => {},
  RenderSMSVerificationForm: () => null,
})

export const useSMSVerificationContext = () => useContext(SMSVerificationContext)

export const SMSVerificationProvider = ({ children }) => {
  const [inVerification, setInVerification] = useState(false)

  const [errMsg, setErrMsg] = useState(null)
  const [resent, setResent] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const formRef = useRef(null)

  const onSubmit =
    ({ onSuccess }) =>
    async (e) => {
      e.preventDefault()
      const formData = new FormData(e.target)
      const { code } = Object.fromEntries(formData)
      setLoading(true)
      try {
        const data = await smsValidation(code)
        if (data?.[0]?.code === 'SMS Envoyé') {
          setResent(true)
          formRef.current?.reset()
          setLoading(false)
          return
        }
        setInVerification(false)
        setResent(false)
        onSuccess?.()
      } catch (e) {
        if (e.response?.data?.message) {
          setErrMsg(e.response?.data?.message)
        } else {
          setErrMsg('Code invalide')
        }
      }
      setLoading(false)
    }

  const RenderSMSVerification = useMemo(() => {
    if (loading) {
      return LoadingSpinner
    }
    return (props) => {
      return (
        <div style={{ boxShadow: '3px 2px 8px 3px rgba(0, 0, 0, 0.2)', padding: '30px 50px' }}>
          <h3 style={{ marginBottom: '0', paddingTop: '30px' }}>Vérification SMS</h3>
          <div className="user-edit">
            <div>
              <RenderSMSVerificationForm handleSubmit={onSubmit(props)} formRef={formRef} />
              <p style={{ paddingTop: '0', paddingBottom: '30px', color: '#c0301e' }}>{errMsg}</p>
              {resent && <p>Un autre SMS vous a été envoyé</p>}
            </div>
          </div>
        </div>
      )
    }
  }, [onSubmit, loading])

  useEffect(() => {
    setInVerification(false)
  }, [location])

  return (
    <SMSVerificationContext.Provider
      value={{ inVerification, setInVerification, RenderSMSVerification }}
    >
      {children}
    </SMSVerificationContext.Provider>
  )
}
