import React from 'react'

import { StyleSheet, Text, View } from '@react-pdf/renderer'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

export const GlobalFooter = ({ title = '', withFooter = true, withPagination = true }) => {
  return (
    <>
      <View style={styles.legalContainer} fixed>
        <Text style={styles.legalText}>La Financière Libre</Text>
        <Text style={styles.legalText}>Projet expérimental de portefeuille numérique</Text>
      </View>
      {withFooter && (
        <View style={styles.dateContainer} fixed>
          <Text>{moment().format('DD/MM/YYYY')}</Text>
        </View>
      )}
      {withFooter && !isEmpty(title) && (
        <View style={styles.footerContainer} fixed>
          <Text>{title}</Text>
        </View>
      )}
      {withPagination && (
        <View style={styles.paginationContainer} fixed>
          <Text
            fixed
            render={({ pageNumber, totalPages }) =>
              withPagination ? `${pageNumber} / ${totalPages}` : ''
            }
          />
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  dateContainer: {
    position: 'absolute',
    fontSize: 9,
    fontWeight: 900,
    bottom: 18,
    left: 0,
    paddingHorizontal: 18,
  },
  footerContainer: {
    position: 'absolute',
    fontSize: 9,
    fontWeight: 900,
    bottom: 18,
    left: 0,
    right: 0,
    textAlign: 'center',
    paddingHorizontal: 18,
  },
  paginationContainer: {
    position: 'absolute',
    fontSize: 9,
    fontWeight: 900,
    bottom: 18,
    right: 0,
    paddingHorizontal: 18,
  },
  legalContainer: {
    position: 'absolute',
    fontSize: 9,
    fontWeight: 900,
    bottom: 40,
    left: 0,
    right: 0,
    textAlign: 'center',
    paddingHorizontal: 18,
  },
  legalText: {
    fontFamily: 'Helvetica-Oblique',
    paddingBottom: 4,
  },
})
