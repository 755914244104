export default class Transaction {
  id: string
  name: string // Raison de la transaction en libellé
  from: string
  id_opération: string
  senderFullName: string
  receiverFullName: string
  to: string
  amount: number
  // status: string = 'SUCCESS'
  time: Date
  date: Date
  account: string
  nature: string
  tier: string

  constructor(data: any) {
    this.id = data.id
    this.id_opération = data.id_opération
    this.name = data.name
    this.tier = data.tier
    this.from = data.from
    this.senderFullName = data.senderFullName
    this.receiverFullName = data.receiverFullName
    this.to = data.to
    this.amount = data.amount
    // this.status = data.status
    this.time = new Date(data.time)
    this.date = data.time
    this.account = data.account
    this.nature = data.nature
  }
}

//   "id": "633c0e9390d226283dc38654",
//   "name": "Don test admin to user",
//   "from": "631ccdf4930f0a562d6661b4",
//   "senderFullName": "Florian ROUANET",
//   "receiverFullName": "Florian UserTest",
//   "to": "6334a30af69df021f91de3ca",
//   "amount": 60,
//   "status": "SUCCESS",
//   "time": 1664880275703
